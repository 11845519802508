import { AnyAction, ThunkDispatch, createAsyncThunk } from '@reduxjs/toolkit';
import { AppState } from '../types/AppState';

export type AppDispatch = ThunkDispatch<AppState, unknown, AnyAction>; // TODO: connect this to the store.

export const createAppThunk =
  <T>(thunkFunction: (arg: T, thunkApi: { dispatch: AppDispatch; getState: () => AppState }) => void) =>
  (arg: T) =>
  (dispatch: AppDispatch, getState: () => AppState) =>
    thunkFunction(arg, { dispatch, getState });

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: AppState;
  dispatch: AppDispatch;
}>();
