import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { usePath } from 'hookrouter';
import { AppState } from '../types/AppState';
import { useAppDispatch, useAppSelector } from '../hooks';
import { unknownGroup } from '../constants/Group';
import {
  configuratorToConfiguratorSupplier,
  getClientIdFromClientSupplier,
  getSupplierKeyFromClientSupplier,
  mapClientAndSupplierToClientSupplier,
  mapConfiguratorToClientId,
} from '../utils/clientIdUtils';
import { getEnabledOnProperty } from '../utils/vendorDataUtils';
import { Configurator } from '../types/Configurator';
import { I18nKeys } from '../constants/I18nKeys';
import { i18n } from '../i18n';
import { setConfigurator } from '../ducks/dialogSlice';
import { hasSuppliers, viewingDealer } from '../utils/supplierUtils';
import { setSurchargeSupplierKey } from '../ducks/pricingSlice';
import { setSelectedClientId, setSelectedTabId } from '../ducks/viewerSlice';
import { PricingTab } from '../constants/Pricing';
import { AppRoutes } from '../constants/AppRoutes';
import { setPricingTab } from '../middleware/viewerThunk';

const useStyles = makeStyles((theme: Theme) => {
  let textColor = theme.palette.secondary.contrastText;
  const isWhite = ['#fff', '#ffffff'].includes(theme.palette.secondary.main.toLowerCase());
  if (isWhite) {
    textColor = theme.palette.getContrastText(theme.palette.secondary.main);
  }
  return {
    select: {
      backgroundColor: theme.palette.secondary.main,
      '& > svg': {
        color: textColor,
      },
      margin: '0px 0px 0px 5px',
      alignSelf: 'center',
    },
    selectDisplay: {
      display: 'flex',
      alignItems: 'center',
      color: textColor,
      fontWeight: 'darker',
      fontSize: '20px',
      paddingLeft: theme.spacing(2),
      margin: '0px 5px 0px 0px',
    },
    singleSupplier: {
      display: 'flex',
      alignItems: 'center',
      color: textColor,
      fontWeight: 'darker',
      fontSize: '20px',
      paddingLeft: theme.spacing(2),
      margin: '0px 5px 0px 0px',
    },
  };
});

const getTopBarTitle = (path: string) => {
  if (path.startsWith(AppRoutes.Pricing)) {
    return I18nKeys.MenuPricingOption;
  }
  if (path.startsWith(AppRoutes.Integrations)) {
    return I18nKeys.MenuIntegrationsOption;
  }
  return '';
};

const findInitialClientSupplier = (configs: Configurator[], id?: string): string => {
  // Is the current id already valid?
  if (
    id &&
    configs.some((c) =>
      c.suppliers?.some((s) => mapClientAndSupplierToClientSupplier(c.clientId, s.supplierKey) === id),
    )
  )
    return id;

  const clientId = getClientIdFromClientSupplier(id);
  let selectedClientSupplierConfig = configs.filter((c) => c.clientId === clientId);
  if (selectedClientSupplierConfig.length === 0) {
    selectedClientSupplierConfig = configs;
  }

  const [selectedClientSupplier] = configuratorToConfiguratorSupplier(selectedClientSupplierConfig);
  return selectedClientSupplier;
};

export const TopBarSupplierSelect: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const path = usePath();

  const { clientId = '' } = useAppSelector((state: AppState) => state?.clientData);
  const { group = unknownGroup } = useAppSelector((state: AppState) => state?.currentUser);
  const { configurators: configs = [] } = group;
  const { selectedTabId, selectedClientId } = useAppSelector((state: AppState) => state?.viewer);
  const {
    surcharge: { supplierKey = '' },
  } = useAppSelector((state: AppState) => state?.pricing);
  const groupConfigs = group.configurators;

  const [filteredConfigs, setFilteredConfigs] = useState(configs);
  const [currentClientSupplier, setCurrentClientSupplier] = useState('');

  const selectedId = selectedTabId || selectedClientId;

  useEffect(() => {
    const newConfigs = configs.filter((c) => getEnabledOnProperty(c.vendorData, 'clientId', false));
    if (JSON.stringify(filteredConfigs) !== JSON.stringify(newConfigs)) setFilteredConfigs(newConfigs);
  }, [filteredConfigs, configs]);

  useEffect(() => {
    const setClientAndSupplier = (
      tabClientId: string,
      tabSupplierKey: string,
      tabGroupConfigs?: Configurator[],
    ): void => {
      if (tabClientId !== clientId || tabSupplierKey !== supplierKey) {
        dispatch(setSurchargeSupplierKey(tabSupplierKey));
        if (tabGroupConfigs)
          setConfigurator(tabGroupConfigs.find((c) => c.clientId === tabClientId) || tabGroupConfigs[0]);
      }
    };

    const setSelectTabAndClientId = (clientSupplier: string, cid: string) => {
      dispatch(setSelectedTabId(clientSupplier));
      dispatch(setSelectedClientId(cid));
    };

    // Finding a clientSupplier to start with (either the selectedTabId or selectedClientId or the first one)
    if (!currentClientSupplier && filteredConfigs.length > 0) {
      setCurrentClientSupplier(findInitialClientSupplier(filteredConfigs, selectedId));
    }

    const selectedClientConfig = filteredConfigs.find((c) => c.clientId === clientId);

    // clientSupplier should match the selectedTabId or selectedClientId
    const newClientId = getClientIdFromClientSupplier(currentClientSupplier);
    const newSupplierKey = hasSuppliers(selectedClientConfig)
      ? getSupplierKeyFromClientSupplier(currentClientSupplier)
      : '';
    if (currentClientSupplier && selectedId !== currentClientSupplier) {
      setSelectTabAndClientId(currentClientSupplier, newClientId);
      setClientAndSupplier(newClientId, newSupplierKey, groupConfigs);
    }
  }, [
    selectedTabId,
    selectedClientId,
    selectedId,
    filteredConfigs,
    currentClientSupplier,
    setCurrentClientSupplier,
    dispatch,
    clientId,
    supplierKey,
    groupConfigs,
  ]);

  const changeSupplier = (supplier: string) => {
    if (viewingDealer(supplier, configs)) dispatch(setPricingTab(PricingTab.Surcharge));
    setCurrentClientSupplier(supplier);
  };

  const showTabs =
    filteredConfigs.length > 1 ||
    (filteredConfigs[0] && filteredConfigs[0].suppliers && filteredConfigs[0].suppliers.length > 1);

  return (
    <div>
      {showTabs ? (
        <Select
          variant="standard"
          disableUnderline
          value={currentClientSupplier}
          onChange={(e: SelectChangeEvent<string>) => changeSupplier(e.target.value)}
          className={classes.select}
          SelectDisplayProps={{
            className: classes.selectDisplay,
          }}
          defaultValue=""
          color="secondary"
        >
          {filteredConfigs.map((configurator) => {
            const configuratorClientId = mapConfiguratorToClientId(configurator);
            if (configurator.suppliers && configurator.suppliers.length > 0) {
              return configurator.suppliers.map((supplier) => {
                const clientSupplier = mapClientAndSupplierToClientSupplier(configuratorClientId, supplier.supplierKey);
                return (
                  <MenuItem value={clientSupplier} key={clientSupplier} id={clientSupplier}>
                    <div>{`${configurator.name}${
                      (configurator.suppliers || []).length > 1
                        ? `${i18n.t(getTopBarTitle(path))} - ${supplier.supplierName || supplier.supplierKey}`
                        : ``
                    }`}</div>
                  </MenuItem>
                );
              });
            }
            return (
              <MenuItem value={configuratorClientId} key={configuratorClientId} id={configuratorClientId}>
                {`${i18n.t(getTopBarTitle(path))} - ${configurator.name}`}
              </MenuItem>
            );
          })}
        </Select>
      ) : (
        <div className={classes.singleSupplier}>{`${i18n.t(getTopBarTitle(path))}`}</div>
      )}
    </div>
  );
};
