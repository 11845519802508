export enum Dialogs {
  AdyenPaymentsNotification = 'Adyen-payments-notification',
  AllowedHtmlTags = 'Allowed-Html-Tags',
  ClientDataGoToSourceData = 'Client-Data-Go-To-Source-Data',
  ClientDataNote = 'Client-Data-Note',
  ClientDataHotfixBranch = 'Client-Data-Hotfix-Branch',
  ClientDataPublish = 'Client-Data-Publish',
  ClientDataPublishUpdates = 'Client-Data-Publish-Updates',
  ClientDataPublishResult = 'Client-Data-Publish-Result',
  ClientDataNewSupplierUpdates = 'Client-Data-New-Supplier-Updates',
  ClientDataPreview = 'Client-Data-Preview',
  ClientDataCantPublish = 'Client-Data-Cant-Publish',
  ClientDataCantGenerateIconsDialog = 'Client-Data-Cant-Generate-Icons',
  ClientDataRevertBranch = 'Client-Data-Revert-Branch',
  Configurator = 'Configurator',
  ClientDataRollback = 'Client-Data-Rollback',
  Confirmation = 'Confirmation',
  Dealer = 'Dealer',
  EmailDomain = 'Email-Domain',
  FilterSelection = 'Filter-Selection',
  Impersonation = 'Impersonation',
  Integations = 'integrations',
  IntegationsDeactivate = 'integrations-deactivate',
  I18n = 'I18n',
  Language = 'Language',
  Loading = 'Loading',
  LoadOptionsToGenerateIcons = 'Load-Options-To-Generate-Icons',
  Notification = 'Notification',
  PricingBaseAddSize = 'Pricing-Base-Add-Size',
  PricingBaseNote = 'Pricing-Base-Note',
  PricingBasePreview = 'Pricing-Base-Preview',
  PricingSheetName = 'Pricing-Sheet-Name',
  PricingComponentEdit = 'Pricing-Component-Edit',
  PricingClientUpdatePreview = 'Pricing-Client-Update-Preview',
  PricingContactSupport = 'Pricing-Contact-Support',
  PricingSurchargePreview = 'Pricing-Surcharge-Preview',
  PricingPublish = 'Pricing-Publish',
  PricingPublishResult = 'Pricing-Publish-Result',
  User = 'User',
  VerifiedQuotes = 'Verified-Quotes',
  WhatsNew = 'Whats-New',
  WhatsNewPreview = 'Whats-New-Preview',
  SceneEnvironment = 'Scene-Environment',
  SetupPaymentIntegration = 'setup-payment-integration',
  SetupPaymentIntegrationConfirmation = 'setup-payment-integration-confirmation',
  SiteDetailPublishResult = 'Site-Detail-Publish-Result',
}
