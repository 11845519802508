import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useAppSelector } from '../hooks';
import { PricingTab } from '../constants/Pricing';
import { PRICING_CLIENT_UPDATE_SEARCH_HEIGHT, PricingClientUpdateSearch } from './PricingClientUpdateSearch';
import {
  PRICING_CLIENT_UPDATE_NAVIGATION_HEIGHT,
  PricingClientUpdateNavigation,
} from './PricingClientUpdateNavigation';

export const PRICING_CLIENT_UPDATE_SPACER_HEIGHT = 5;
export const PADDING_TOP = 20;
export const PADDING_BOTTOM = 5;

export const PricingClientUpdateHeader: React.FC<{
  searchValue: string;
  setSearchValue: (val: string) => void;
  headerHeight: number;
  setHeaderHeight: (height: number) => void;
}> = ({ searchValue, setSearchValue, headerHeight, setHeaderHeight }) => {
  const { selectedCategoryKey: sizeBasedCategoryKey, selectedPricingSheetId } = useAppSelector(
    (state) => state.pricing.sizeBased,
  );
  const { selectedPricingTabId: pricingTab } = useAppSelector((state) => state.viewer);

  const [components, setComponents] = React.useState<{ component: JSX.Element; height: number }[]>([]);

  useEffect(() => {
    const headerComponents = [
      {
        component: (
          <PricingClientUpdateSearch
            key="client-update-header-search"
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        ),
        height: PRICING_CLIENT_UPDATE_SEARCH_HEIGHT,
      },
      {
        component: <div key="client-update-header-spacer" style={{ height: '5px', width: '100%' }} />,
        height: PRICING_CLIENT_UPDATE_SPACER_HEIGHT,
      },
      {
        component: (
          <PricingClientUpdateNavigation key="client-update-header-navigation" setSearchValue={setSearchValue} />
        ),
        height: PRICING_CLIENT_UPDATE_NAVIGATION_HEIGHT,
      },
    ];
    setComponents(headerComponents);
  }, [searchValue, setSearchValue, pricingTab, sizeBasedCategoryKey, selectedPricingSheetId]);

  useEffect(() => {
    if (pricingTab === PricingTab.SizeBased && sizeBasedCategoryKey) {
      setHeaderHeight(0);
      return;
    }
    setHeaderHeight(components.reduce((acc, { height }) => acc + height, 0) + PADDING_TOP + PADDING_BOTTOM);
  }, [components, setHeaderHeight, sizeBasedCategoryKey, pricingTab]);

  return (
    <Grid
      container
      style={{
        padding: `${PADDING_TOP}px 30px ${PADDING_BOTTOM}px`,
        height: `${headerHeight}px`,
      }}
      item
      xs={12}
    >
      {components.map(({ component }) => component)}
    </Grid>
  );
};
