import moment from 'moment';
import { WhatsNew as WhatsNewType } from '../types/WhatsNew';

export const unknownWhatsNew: WhatsNewType = {
  createdBy: '',
  clientId: undefined,
  readonly: false,
  id: 0,
  date: moment(new Date()).format('MM/DD/YYYY'),
  title: '',
  message: '',
  updating: false,
};

export enum WhatsNewCacheTagType {
  WhatsNew = 'WhatsNew',
}
