import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { useAppSelector } from '../hooks';
import { usePricingRepo } from '../hooks/usePricingRepo';
import { PricingSheetRegion } from '../types/Region';
import { I18nKeys } from '../constants/I18nKeys';
import hiddenPriceSrc from '../images/hiddenPriceIcon.svg';
import { PricingSheetSubheader } from './PricingSheetSubheader';

const useStyles = makeStyles(() => ({
  subheaderContainer: {
    marginBottom: '20px',
  },
}));

export const PricingBaseHiddenPriceHeader: React.FC<{ regions: PricingSheetRegion[] }> = ({ regions }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [hiddenRegions, setHiddenRegions] = React.useState<string[]>([]);
  const [styleIsHidden, setStyleIsHidden] = React.useState<boolean>(false);

  const { pricingBaseSheets = [], isLoadingRegions } = usePricingRepo({
    usePricingSheetBase: true,
    useSupplierRegions: true,
  });

  const { selectedPricingSheetId } = useAppSelector((state) => state.pricing.base);
  const { clientId } = useAppSelector((state) => state.clientData);

  useEffect(() => {
    if (!selectedPricingSheetId || isLoadingRegions) return;

    const selectedPricingSheet = pricingBaseSheets.find((sheet) => sheet.id === selectedPricingSheetId);
    if (!selectedPricingSheet) return;

    const { prices = [] } = selectedPricingSheet;
    const hiddenRegionKeys = regions
      .filter(({ regionKey }) => prices.every(({ hidden }) => hidden[regionKey]))
      .map(({ regionKey }) => regionKey);
    setHiddenRegions(hiddenRegionKeys);
    setStyleIsHidden(regions.length === hiddenRegionKeys.length);
  }, [clientId, selectedPricingSheetId, isLoadingRegions, regions]);

  if (!styleIsHidden && !hiddenRegions.length) return null;
  return (
    <PricingSheetSubheader
      subheader={t(
        styleIsHidden ? I18nKeys.PricingBaseHiddenStylePriceHeader : I18nKeys.PricingBaseHiddenRegionPriceHeader,
        {
          regions: hiddenRegions.length,
        },
      )}
      iconSrc={hiddenPriceSrc}
      classes={classes}
    />
  );
};
