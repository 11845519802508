/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { TFunction } from 'react-i18next';
import { notAssigned } from '../components/OrderOwnerSelector';
import { newLead, orderStatusOptions } from '../components/OrderStatusSelector';
import { I18nKeys } from '../constants/I18nKeys';
import { OrderStatusId } from '../constants/OrderStatus';
import { i18n } from '../i18n';
import { CustomFilter } from '../types/CustomFilter';
import { unknownDealer } from '../types/Dealer';
import { GroupMember } from '../types/GroupMember';
import { Order, SubmitStatus } from '../types/Order';
import { OrderDealer } from '../types/OrderDealer';
import { OrderOwner, unknownOwner } from '../types/OrderOwner';
import { OrderStatus } from '../types/OrderStatus';
import { getVendorFromClientId, getSiteFromClientId } from './clientIdUtils';
import { Configurator } from '../types/Configurator';
import { FilterMenuItems } from '../constants/FilterMenuItems';
import { getCurrentMonthDateRange, getLastDaysDateRange, getPriorMonthDateRange } from './dateUtils';
import { DateRange } from '../types/DateRange';

export const mapSubmitStatusToLabel = (submitStatus: string): string => {
  const { t } = i18n;
  switch (submitStatus) {
    case SubmitStatus.Draft:
      return t(I18nKeys.SubmitStatusDraft);
    case SubmitStatus.Save:
      return t(I18nKeys.SubmitStatusSave);
    case SubmitStatus.SaveToList:
      return t(I18nKeys.SubmitStatusSaveToList);
    case SubmitStatus.Quote:
      return t(I18nKeys.SubmitStatusQuote);
    case SubmitStatus.Deposit:
    case SubmitStatus.DepositPaying:
    case SubmitStatus.DepositToken:
    case SubmitStatus.DepositCharged:
    case SubmitStatus.DepositPayLater:
    case SubmitStatus.ExternalCheckout:
      return t(I18nKeys.SubmitStatusCheckout);
    case SubmitStatus.Invoice:
      return t(I18nKeys.SubmitStatusInvoice);
    case SubmitStatus.Popup:
      return t(I18nKeys.SubmitStatusPopup);
    default:
      return t(I18nKeys.SubmitStatusUnknown);
  }
};

export const filterOrdersByDealer = (orders: any[], filter: CustomFilter): any[] =>
  orders.filter((order) =>
    filter.selectedFilterValues.some(
      (selectedValue) =>
        selectedValue.key ===
        (order.orderDealerKey || order.dealerKey || order.orderDealerName || order.dealerName || unknownDealer.name),
    ),
  );

export const filterOrdersByOwner = (orders: any[], filter: CustomFilter): any[] =>
  orders.filter((order) =>
    filter.selectedFilterValues.some(
      (selectedValue) =>
        selectedValue.key === (order.ownerEmail || order.ownerName || order.salesRepEmail || unknownOwner.name),
    ),
  );

export const filterOrdersBySite = (orders: any[], filter: CustomFilter): any[] =>
  orders.filter((order) =>
    filter.selectedFilterValues.some((selectedValue) => selectedValue.key === getVendorFromClientId(order.clientId)),
  );

export const filterOrdersByStatus = (orders: any[], filter: CustomFilter): any[] =>
  orders.filter((order) =>
    filter.selectedFilterValues.some(
      (selectedValue) => selectedValue.key === (order.orderStatusId || OrderStatusId.NewLead),
    ),
  );

export const filterOrdersBySubmitStatus = (orders: any[], filter: CustomFilter): any[] =>
  orders.filter((order) =>
    filter.selectedFilterValues.some(
      (selectedValue) => selectedValue.key === mapSubmitStatusToLabel(order.submitStatus),
    ),
  );

export const getOrderDealerFromOrder = (order: any): OrderDealer => {
  if (order.orderDealerKey) {
    if (order.orderDealerName) {
      return { name: order.orderDealerName, key: order.orderDealerKey };
    }
    return { name: order.orderDealerKey, key: order.orderDealerKey };
  }
  if (order.dealerKey) {
    if (order.dealerName) {
      return { name: order.dealerName, key: order.dealerKey };
    }
    return { name: order.dealerKey, key: order.dealerKey };
  }
  return notAssigned;
};

export const getOrderOwnerFromOrder = (order: any, users?: GroupMember[]): OrderOwner => {
  if (order.ownerEmail) {
    if (order.ownerName) {
      return { name: order.ownerName, email: order.ownerEmail };
    }
    const user = users?.find(({ email }) => email.toLowerCase() === order.ownerEmail.toLowerCase());
    if (user) {
      return { name: user.name, email: user.email.toLowerCase() };
    }
    return { name: order.ownerEmail, email: order.ownerEmail.toLowerCase() };
  }
  if (order.salesRepEmail) {
    if (order.salesRepName) {
      return { name: order.salesRepName, email: order.salesRepEmail };
    }
    const user = users?.find(({ email }) => email.toLowerCase() === order.salesRepEmail.toLowerCase());
    if (user) {
      return { name: user.name, email: user.email.toLowerCase() };
    }
    return { name: order.salesRepEmail, email: order.salesRepEmail.toLowerCase() };
  }
  return notAssigned;
};

export const getOrderStatusFromOrder = (order: any): OrderStatus => {
  if (order.orderStatusId && order.orderStatusFontColor && order.orderStatusBackgroundColor) {
    const { i18nKey } = orderStatusOptions.find(({ id }) => id === order.orderStatusId) || {};
    return {
      id: order.orderStatusId,
      name: order.orderStatusName,
      i18nKey,
      chip: { fontColor: order.orderStatusFontColor, backgroundColor: order.orderStatusBackgroundColor },
    };
  }
  return newLead;
};

export const getOrderStatusLabelFromOrder = (order: any): string => {
  const status = getOrderStatusFromOrder(order);
  return status.i18nKey ? i18n.t(status.i18nKey) : status.name || 'New Lead';
};

export const exportToCSV = (
  ordersToExport: Order[],
  t: TFunction<'translation', undefined>,
  configurators: Configurator[],
) => {
  const csvRows = [];
  const headers = [
    t(I18nKeys.TableHeaderCreated),
    t(I18nKeys.TableHeaderName),
    t(I18nKeys.TableHeaderEmail),
    t(I18nKeys.TableHeaderPhone),
    t(I18nKeys.TableHeaderCity),
    t(I18nKeys.TableHeaderDeliveryZip),
    t(I18nKeys.TableHeaderBuildingStyle),
    t(I18nKeys.TableHeaderBuildingSize),
    t(I18nKeys.TableHeaderEstimate),
    t(I18nKeys.TableHeaderReferenceNumber),
    t(I18nKeys.TableHeaderSubmitStatus),
    t(I18nKeys.TableHeaderDesign),
    t(I18nKeys.TableHeaderDealer),
    t(I18nKeys.TableHeaderDealerEmail),
    t(I18nKeys.TableHeaderOwner),
    t(I18nKeys.TableHeaderOwnerEmail),
    t(I18nKeys.TableHeaderSite),
    t(I18nKeys.TableHeaderStatus),
  ];
  csvRows.push(headers.join(','));

  ordersToExport.forEach((row) => {
    const flattenedRow = [
      moment(row.date).format('MM/DD/YYYY'),
      row.customerName,
      row.customerEmail,
      row.customerPhone,
      row.city,
      row.zipCode,
      row.buildingStyle,
      row.buildingSize,
      row.totalPrice,
      row.versionedEmailId,
      mapSubmitStatusToLabel(row.submitStatus),
      row.orderDealerLink,
      row.dealerName,
      row.dealerEmail,
      row.ownerName,
      row.ownerEmail,
      getSiteFromClientId(row.clientId, configurators),
      getOrderStatusLabelFromOrder(row),
    ];

    // Escape double quotes in values
    const escapedRow = flattenedRow.map((value) => `${value || ''}`.replace(/"/g, '""'));
    csvRows.push(`"${escapedRow.join('","')}"`);
  });

  // Create a Blob from the CSV string
  const csvString = csvRows.join('\n');
  const blob = new Blob([csvString], { type: 'text/csv' });

  return blob;
};

export const getFilterDateRange = (filter: FilterMenuItems): DateRange | undefined => {
  switch (filter) {
    case FilterMenuItems.Days45:
      return getLastDaysDateRange(45);
    case FilterMenuItems.Days90:
      return getLastDaysDateRange(90);
    case FilterMenuItems.MonthCurrent:
      return getCurrentMonthDateRange();
    case FilterMenuItems.MonthLast:
      return getPriorMonthDateRange();
    case FilterMenuItems.AllTime:
    case FilterMenuItems.Custom:
      return undefined;
    default:
      return getLastDaysDateRange(45);
  }
};
