import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { splitByMatches } from '../utils/searchUtils';
import { useAppSelector } from '../hooks';
import { getPricingSheetLabelParts } from '../utils/pricingSheetUtils';
import { PricingSheet } from '../types/PricingSheet';
import { I18nKeys } from '../constants/I18nKeys';
import pricingUpchargeIcon from '../images/pricingUpchargeIcon.svg';

type Props = {
  classes?: Record<string, string>;
  pricingSheet: PricingSheet;
  setRef: (instance: HTMLSpanElement | null) => void;
  searchTerm?: string;
};

export const PricingAttributesLabel: React.FC<Props> = ({ classes = {}, pricingSheet, setRef, searchTerm = '' }) => {
  const { t } = useTranslation();

  const pricingTab = useAppSelector((state) => state.viewer.selectedPricingTabId || '');

  return (
    <ul className={classes.list}>
      {/* Per design the title is shown with a single bullet point, sorted alphabetically */}
      {getPricingSheetLabelParts(pricingSheet, pricingTab, t).map((label = '') => {
        const displayAsSidingUpcharge = label === t(I18nKeys.PricingSheetAttributeSidingUpcharge);
        return (
          <li key={label}>
            <Typography
              ref={setRef}
              className={[classes.attributes, ...(displayAsSidingUpcharge ? [classes.sidingUpcharge] : [])].join(' ')}
              variant="body2"
            >
              {splitByMatches(label, searchTerm, {}).map((part, index) => (
                <span
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${label}-${index}`}
                  style={{ backgroundColor: part.highlight ? '#FDFF80' : undefined, verticalAlign: 'middle' }}
                >
                  {part.text}
                </span>
              ))}
              {displayAsSidingUpcharge && (
                <img
                  alt="Siding upcharge icon"
                  src={pricingUpchargeIcon}
                  style={{ marginLeft: '5px', verticalAlign: 'middle' }}
                />
              )}
            </Typography>
          </li>
        );
      })}
    </ul>
  );
};
