import { IconButton } from '@mui/material';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import React from 'react';
import { ExpandMore, KeyboardArrowRight } from '@mui/icons-material';

export const RowGroupExpandButton: React.FC<GridRenderCellParams> = ({ id, field, rowNode }: GridRenderCellParams) => {
  const apiRef = useGridApiContext();

  const [expanded, setExpanded] = React.useState(false);

  if (rowNode.type !== 'group') return null;

  const handleExpand = (event: React.MouseEvent<HTMLButtonElement>) => {
    const newExpanded = !rowNode?.childrenExpanded;
    setExpanded(newExpanded);
    apiRef.current.setRowChildrenExpansion(id, newExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return <IconButton onClick={handleExpand}>{expanded ? <ExpandMore /> : <KeyboardArrowRight />}</IconButton>;
};
