import { Card, CardContent, CardHeader, CardMedia, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { RefObject, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../hooks';
import { CLIENT_UPDATE_CATEGORY_MAPPING } from '../constants/PricingClientUpdate';
import { setSelectedComponentCategoryKey, setSelectedSizeBasedCategoryKey } from '../ducks/pricingSlice';
import { useClientUpdatePricingRepo } from '../hooks/useClientUpdatePricingRepo';
import { filterClientUpdateCategories } from '../utils/pricingClientUpdateUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { PricingTab } from '../constants/Pricing';
import { ComponentCategoryKey, SizeBasedCategoryKey } from '../constants/ClientUpdateCategoryKey';
import ComingSoonImage from '../images/coming-soon.png';

const SECTION_PADDING = 30;
const CARD_SPACING = 24;
const MIN_CARD_SIZE = 220;

const useStyles = makeStyles(() => ({
  categories: {
    margin: '15px 0px 0px',
    padding: `0px 0px 0px ${SECTION_PADDING}px`,
    width: '100%',
    minHeight: 0,
  },
  cardContent: {
    padding: '0px',
    height: 'calc(100% - 54px)',
    '&:last-child': {
      paddingBottom: '0px',
    },
  },
  cardMedia: {
    height: '100%',
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    objectFit: 'contain',
  },
  card: {
    margin: `0px ${CARD_SPACING}px ${CARD_SPACING}px 0px`,
    borderRadius: '14px',
    cursor: 'pointer',
    aspectRatio: '1 / 1', // Make the card square
    position: 'relative',
    display: 'inline-block',
  },
  textOverlay: {
    position: 'absolute',
    padding: '18px',
    margin: 0,
    bottom: 0,
    right: 0,
  },
}));

type Props = {
  searchValue?: string;
  setSearchValue: (value: string) => void;
};

const resizeCategoryCards = (ref: RefObject<HTMLDivElement>) => {
  const fullSectionWidth = ref.current?.clientWidth;
  if (!fullSectionWidth) return;
  const sectionWidth = fullSectionWidth - SECTION_PADDING * 2;

  if (ref.current) {
    const cardCount = Math.floor(sectionWidth / (MIN_CARD_SIZE + CARD_SPACING));
    const cardWidth = `${sectionWidth / cardCount - CARD_SPACING}px`;
    (Array.from(ref.current.children) as HTMLDivElement[]).forEach((child, i) => {
      // eslint-disable-next-line no-param-reassign
      child.style.width = cardWidth;
    });
  }
};

export const PricingClientUpdateCategories: React.FC<Props> = ({ searchValue = '', setSearchValue }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const ref = React.useRef<HTMLDivElement>(null);

  const pricingTab = useAppSelector((state) => state.viewer.selectedPricingTabId);

  const { clientUpdateCategoriesList = [], isLoadingClientUpdateCategoriesList } = useClientUpdatePricingRepo({
    useClientUpdateCategories: true,
  });

  const filteredComponentCategories = filterClientUpdateCategories(searchValue, clientUpdateCategoriesList, t);

  useEffect(() => {
    const handleResize = () => resizeCategoryCards(ref);
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isLoadingClientUpdateCategoriesList, pricingTab]);

  useEffect(() => {
    resizeCategoryCards(ref);
  }, [filteredComponentCategories]);

  if (isLoadingClientUpdateCategoriesList) return <CircularProgress style={{ alignSelf: 'center' }} color="primary" />;
  return (
    <div className={classes.categories} ref={ref}>
      {filteredComponentCategories.length > 0 ? (
        filteredComponentCategories.map(({ key, count, changes, disabled = false }) => (
          <Card
            key={key}
            elevation={0}
            className={classes.card}
            style={{
              border: `1px solid ${changes ? '#E0AC78' : '#D0D0D0'}`,
              ...(disabled ? { opacity: 0.4, cursor: 'default' } : {}),
            }}
            onClick={() => {
              if (disabled) return;
              setSearchValue('');
              dispatch(
                pricingTab === PricingTab.Component
                  ? setSelectedComponentCategoryKey(key as ComponentCategoryKey)
                  : setSelectedSizeBasedCategoryKey(key as SizeBasedCategoryKey),
              );
            }}
          >
            <CardHeader
              style={{
                padding: '15px 20px',
                backgroundColor: changes ? '#E4A76733' : '#F5F5F5',
              }}
              color="text.primary"
              fontSize="16px"
              titleTypographyProps={{ variant: 'body1', fontSize: '16px', fontWeight: 700, color: 'text.primary' }}
              title={t(CLIENT_UPDATE_CATEGORY_MAPPING[key]?.label)}
            />
            <CardContent className={classes.cardContent}>
              <CardMedia className={classes.cardMedia}>
                <img
                  src={disabled ? ComingSoonImage : CLIENT_UPDATE_CATEGORY_MAPPING[key]?.image}
                  alt="component"
                  draggable={false}
                  style={{ height: '100%', width: 'auto' }}
                />
              </CardMedia>
              {!disabled && (
                <Typography
                  className={classes.textOverlay}
                  gutterBottom
                  variant="body1"
                  component="div"
                  color="text.secondary"
                >
                  {count}
                </Typography>
              )}
            </CardContent>
          </Card>
        ))
      ) : (
        <Typography
          variant="body1"
          component="div"
          color="text.secondary"
          style={{
            margin: '10% auto',
          }}
        >
          {t(I18nKeys.PricingComponentNoCategoriesFound)}
        </Typography>
      )}
    </div>
  );
};
