import { IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import { useAppSelector } from '../hooks';
import { unknownUser } from '../types/User';
import { AppState } from '../types/AppState';
import { isIdeaRoomUser as isIdeaRoomUserFunc } from '../utils/userUtils';

interface Props {
  id: string;
  actions: {
    label: string;
    onClick: (handleClose: () => void) => void;
    ideaRoomOnly?: boolean;
  }[];
}

export const ActionsMenu: React.FC<Props> = ({ id, actions }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isIdeaRoomUser = useAppSelector(({ currentUser: { user = unknownUser } }: AppState) =>
    isIdeaRoomUserFunc(user),
  );

  function handleClick(event: React.MouseEvent<HTMLElement>): void {
    event.stopPropagation(); // Prevent table row event from being called
    setAnchorEl(event.currentTarget);
  }

  function handleClose(event: React.MouseEvent<HTMLElement>): void {
    event.stopPropagation(); // Prevent table row event from being called
    setAnchorEl(null);
  }

  return (
    <div>
      <IconButton
        style={{ padding: '6px' }}
        aria-controls={`${id}-button`}
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actions
          .filter(({ ideaRoomOnly }) => !ideaRoomOnly || isIdeaRoomUser)
          .map(({ onClick, label }) => (
            <MenuItem key={label} onClick={(event) => onClick(() => handleClose(event))}>
              <ListItemText primary={label} />
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};
