import { createApi } from '@reduxjs/toolkit/query/react';
import { Notification, SystemNotificationKey } from '@idearoom/types';
import { API_NAMES } from '../constants/App';
import { amplifyAPIBaseQuery, getRequestHeader } from '../utils/apiUtils';

export const notificationApi = createApi({
  reducerPath: 'notificationApi',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: amplifyAPIBaseQuery({
    apiName: API_NAMES.API_PUBLIC,
    baseUrl: '/v1/internal/notification',
  }),
  endpoints: (builder) => ({
    getPaymentSignupNotification: builder.query<
      Notification[],
      {
        groupId: string;
      }
    >({
      query: ({ groupId }) => ({
        url: `/?key=${SystemNotificationKey.PaymentSignup}&unreadOnly=true`,
        method: 'get',
        init: {
          headers: getRequestHeader({ groupId }),
        },
        displayToastOnError: false,
      }),
    }),

    addNotificationEvent: builder.mutation<
      void,
      {
        groupId: string;
        clientId: string;
        notificationId: number | SystemNotificationKey;
      }
    >({
      query: ({ groupId, clientId, notificationId }) => ({
        url: `/${notificationId}/event`,
        method: 'post',
        init: {
          headers: getRequestHeader({ groupId, clientId }),
        },
      }),
    }),
  }),
});

export const { useGetPaymentSignupNotificationQuery } = notificationApi;
