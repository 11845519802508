import React from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { ICellEditorReactComp } from 'ag-grid-react';
import _ from 'lodash';
import { AuthoringColumnProduct } from '@idearoom/types';
import { useAppSelector } from '../hooks';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { useGetClientDataTablesDataQuery } from '../services/clientDataApi';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { mapClientIdToConfiguratorAndVendor } from '../utils/clientIdUtils';
import { DataGridAutocompleteCellEditor } from './DataGridAutocompleteCellEditor';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { SHEDS_STYLE_TABLE } from '../constants/ClientData';
import { ClientDataType } from '../constants/ClientDataType';
import { KEY_COLUMN } from '../constants/ClientDataColumn';
import { unknownGroup } from '../constants/Group';
import { IDEAROOM_CLIENT_ID } from '../constants/ClientId';

export const ClientDataLookupCellEditor = React.forwardRef<ICellEditorReactComp, ICellEditorParams>(
  (props: ICellEditorParams, ref) => {
    const { colDef } = props;

    const { group: { groupId } = unknownGroup } = useAppSelector((state) => state?.currentUser);
    const {
      clientId,
      clientDataType,
      clientDataBranch = ClientDataBranch.Main,
    } = useAppSelector((state) => state?.clientData);
    const { tableMetadata } = useClientDataRepo({ useTableMetadata: true });
    const { configurator } = mapClientIdToConfiguratorAndVendor(clientId);

    const columnMetadata = tableMetadata?.metadata[colDef.field || ''];
    const { lookupTables = [], multiple = false, enumType } = columnMetadata || {};
    if (columnMetadata && !lookupTables?.length) {
      // eslint-disable-next-line no-console
      console.error(`${colDef.field} column is using lookupCellEditor but is missing lookupTables from metadata.`);
    }

    const groupedLookupTables = _.groupBy(
      lookupTables,
      (lookupTable) =>
        lookupTable.datasetType +
        (lookupTable.column || '') +
        (lookupTable.table === SHEDS_STYLE_TABLE ? 'shed-style' : ''),
    );

    const queryTablesParams = Object.entries(groupedLookupTables).map(([, tables]) => {
      const dataType = tables[0].datasetType;
      const keyColumn = tables[0].column || KEY_COLUMN;
      let columns = [keyColumn, ClientDataFixedColumns.Product];

      // FIXME: Workaround for IFS Style table
      const isShedStyleTableKey = keyColumn === KEY_COLUMN && tables.some((table) => table.table === SHEDS_STYLE_TABLE);
      if (isShedStyleTableKey) {
        columns = ['property', 'value1', ClientDataFixedColumns.Product];
      }
      return {
        dataType,
        branch: dataType === clientDataType ? clientDataBranch : ClientDataBranch.Main,
        groupId: groupId || '',
        clientId: dataType === ClientDataType.Reference ? IDEAROOM_CLIENT_ID : clientId,
        tableNames: tables.map(({ table }) => table),
        columns,
        keyColumn,
      };
    });

    const { data = [], isLoading } = useGetClientDataTablesDataQuery(
      { tables: queryTablesParams },
      {
        skip: lookupTables.length === 0,
        refetchOnFocus: false,
      },
    );

    const flatData = _.flatMap(data, (gridData, index) =>
      Object.entries(gridData).reduce<
        {
          key: string;
          product: string;
        }[]
      >(
        (acc, [table, rows]) => [
          ...acc,

          ...(table === SHEDS_STYLE_TABLE && queryTablesParams[index].keyColumn === KEY_COLUMN
            ? // FIXME: Workaround for IFS Style table
              rows
                .filter((row) => row.property === 'style key')
                .map((row) => ({ key: row.value1 as string, product: row[ClientDataFixedColumns.Product] as string }))
            : rows.map((row) => {
                if (!(queryTablesParams[index].keyColumn in row)) {
                  // eslint-disable-next-line no-console
                  console.error(
                    `${colDef.field} column is trying to lookup column ${queryTablesParams[index].keyColumn} in ${table} table but it doesn't exist.`,
                  );
                }
                return {
                  key: row[queryTablesParams[index].keyColumn] as string,
                  product: row[ClientDataFixedColumns.Product] as string,
                };
              })),
        ],
        [],
      ),
    );

    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const enumOptions = enumType ? Object.values<string>(require('@idearoom/types')[enumType] || {}) : [];
    const options = Array.from(
      new Set([
        ...enumOptions,
        ...flatData
          .filter(
            (option) =>
              clientDataType === ClientDataType.Reference ||
              option.product === AuthoringColumnProduct.Shared ||
              (configurator === 'carportview'
                ? option.product === AuthoringColumnProduct.Carports
                : option.product === AuthoringColumnProduct.Sheds),
          )
          .map((option) => option.key)
          .filter((option) => option !== null && option !== undefined)
          .map((option) => `${option}`)
          .filter((option) => option.trim() !== ''),
      ]),
    ).sort();

    return (
      <DataGridAutocompleteCellEditor
        cellEditorParams={props}
        ref={ref}
        options={options}
        loading={isLoading}
        multiple={multiple}
      />
    );
  },
);
