import { IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialogs } from '../constants/Dialogs';
import { removeWhatsNew as removeWhatsNewAction, setWhatsNewPreview } from '../ducks/settings';
import { openDialog } from '../ducks/dialogSlice';
import { openConfirmationDialog } from '../ducks/confirmation';
import { WhatsNew } from '../types/WhatsNew';
import { disabled } from '../theme';
import { I18nKeys } from '../constants/I18nKeys';
import { i18n } from '../i18n';
import { useAppDispatch } from '../hooks';

export const WhatsNewMenu: React.FC<{
  whatsNew: WhatsNew;
}> = ({ whatsNew }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function handleClick(event: React.MouseEvent<HTMLElement>): void {
    event.stopPropagation(); // Prevent table row event from being called
    setAnchorEl(event.currentTarget);
  }

  function handleClose(event: React.MouseEvent<HTMLElement>): void {
    event.stopPropagation(); // Prevent table row event from being called
    setAnchorEl(null);
  }

  return (
    <div>
      <IconButton
        style={{ padding: '6px' }}
        aria-controls="group-whats-new-group-menu-btn"
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id="whats-new-group-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <ListItemText
            onClick={(event: React.MouseEvent<HTMLElement>): void => {
              dispatch(setWhatsNewPreview([whatsNew]));
              dispatch(openDialog({ dialog: Dialogs.WhatsNewPreview }));
              handleClose(event);
            }}
            primary={t(I18nKeys.WhatsNewPreview)}
          />
        </MenuItem>
        <MenuItem>
          <ListItemText
            primaryTypographyProps={whatsNew.readonly ? { style: { opacity: disabled } } : {}}
            onClick={(event: React.MouseEvent<HTMLElement>): void => {
              if (!whatsNew.readonly) {
                dispatch(
                  openConfirmationDialog(undefined, t(I18nKeys.WhatsNewConfirmationDialogDescription), undefined, [
                    { label: i18n.t(I18nKeys.DialogDeleteButton), actions: [removeWhatsNewAction(whatsNew)] },
                  ]),
                );
                dispatch(openDialog({ dialog: Dialogs.Confirmation }));
                handleClose(event);
              }
            }}
            primary={t(I18nKeys.DialogDeleteButton)}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};
