import { ComponentCategoryKey, SizeBasedCategoryKey } from '../constants/ClientUpdateCategoryKey';
import { TableData } from './DataGrid';

export type ClientUpdateCategoryKey = ComponentCategoryKey | SizeBasedCategoryKey;

export interface ClientUpdateCategory {
  key: ClientUpdateCategoryKey;
  count: number | null;
  changes: boolean;
  disabled?: boolean;
}

export interface ConditionalPrice {
  rowId: string;
  table: string;
  optionKey: string;
  expression: string;

  price?: string;
  region1?: string;
  region2?: string;
  region3?: string;
  region4?: string;
  region5?: string;
  region6?: string;
  region7?: string;
  order: string;
  priceExpression?: string;
  priceCalculation?: string;
}

export interface ComponentCategoryItem {
  rowId: string;
  table: string;
  label: {
    table: string;
    rowId: string;
    value: string;
  };
  price?: string | null;
  region1?: string | null;
  region2?: string | null;
  region3?: string | null;
  region4?: string | null;
  region5?: string | null;
  region6?: string | null;
  region7?: string | null;
  upgradePrice?: string | null;
  priceExpression?: string | null;
  priceCalculation?: string;
  styleKey?: string;
  wallPosition?: string;
  widthTags?: string;
  height?: string;
  width?: string;
  customExpression?: string;
  order: string;
  key: string;
}

export interface LegHeightPricingSheet {
  id: string;
  isSingleSlope: boolean;
  leg: string;
  legLabel: string;
  widthTags: string;
  customExpression: string | null;
  left: number;
  right: number;
  leftHighestHeight: number;
  leftLowestHeight: number;
  rightHighestHeight: number;
  rightLowestHeight: number;
  maxPrice: number;
  minPrice: number;
}

export type SizeBasedPricingSheet = LegHeightPricingSheet[];

export interface ComponentCategoryItemWithConditions {
  item: ComponentCategoryItem;
  conditions: ConditionalPrice[];
}

export type ComponentUpdate = {
  clientCategoryKey: string | undefined;
  table: string;
  data: TableData;
  column: string;
  oldValue: string | number | null;
  newValue: string | number | null;
};

export const rowGroups = [
  'label',
  'price',
  'region1',
  'region2',
  'region3',
  'region4',
  'region5',
  'region6',
  'region7',
] as Array<keyof ComponentCategoryItem>;
