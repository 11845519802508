/* eslint-disable no-param-reassign */
import { AnyAction } from '@reduxjs/toolkit';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { clientDataApi } from '../services/clientDataApi';
import { unknownGroup } from '../constants/Group';
import { getClientIdFromClientSupplier, getVendorFromClientId } from '../utils/clientIdUtils';
import { PricingTab } from '../constants/Pricing';
import {
  setPricingBaseDataBranch,
  setPricingComponentDataBranch,
  setPricingSizeBasedDataBranch,
} from '../ducks/pricingSlice';
import { ClientDataType } from '../constants/ClientDataType';
import { setClientDataBranch } from '../ducks/clientDataSlice';
import { createAppAsyncThunk } from './thunks';

export const setPricingTab = createAppAsyncThunk<PricingTab | undefined, PricingTab | undefined>(
  'viewer/setPricingTab',
  async (pricingTab, { dispatch, getState }) => {
    const state = getState();
    const { clientData, pricing, viewer, currentUser } = state;
    const { selectedTabId, selectedClientId } = viewer;
    const { group: { groupId } = unknownGroup } = currentUser;
    const { clientId, clientDataBranch } = clientData;
    const {
      component: { pricingDataBranch: componentDataBranch },
      sizeBased: { pricingDataBranch: sizeBasedDataBranch },
      base: { pricingDataBranch: baseDataBranch },
    } = pricing;
    const vendorKey = getVendorFromClientId(clientId);

    let selectedPricingDataBranch = clientDataBranch;
    let pricingTabDataBranch = ClientDataBranch.PricingSurcharge;
    let setPricingTabDataBranch: (branch: ClientDataBranch) => AnyAction = setClientDataBranch;
    switch (pricingTab) {
      case PricingTab.Base:
        selectedPricingDataBranch = baseDataBranch;
        pricingTabDataBranch = ClientDataBranch.Pricing;
        setPricingTabDataBranch = setPricingBaseDataBranch;
        break;
      case PricingTab.Component:
        selectedPricingDataBranch = componentDataBranch;
        pricingTabDataBranch = ClientDataBranch.ClientUpdate;
        setPricingTabDataBranch = setPricingComponentDataBranch;
        break;
      case PricingTab.SizeBased:
        selectedPricingDataBranch = sizeBasedDataBranch;
        pricingTabDataBranch = ClientDataBranch.PricingSizeBased;
        setPricingTabDataBranch = setPricingSizeBasedDataBranch;
        break;
      default:
        break;
    }

    const activeBranchesFetch = dispatch(
      clientDataApi.endpoints.getClientDataBranches.initiate({
        dataType: pricingTab !== PricingTab.Surcharge ? ClientDataType.Supplier : ClientDataType.Vendor,
        clientId,
        groupId,
      }),
    );
    activeBranchesFetch.unsubscribe();
    const { data: activeBranches = [] } = await activeBranchesFetch;

    const clientUpdateBranchExists = activeBranches.find((branch) => branch.branchType === pricingTabDataBranch);
    const existsAndFromClientId = !!clientUpdateBranchExists?.name.includes(vendorKey);

    if (clientId && clientId !== getClientIdFromClientSupplier(selectedTabId || selectedClientId || '')) {
      dispatch(setPricingTabDataBranch(ClientDataBranch.Main));
    }
    if (existsAndFromClientId && selectedPricingDataBranch !== pricingTabDataBranch) {
      dispatch(setPricingTabDataBranch(pricingTabDataBranch));
    }
    if (!existsAndFromClientId && selectedPricingDataBranch !== ClientDataBranch.Main) {
      dispatch(setPricingTabDataBranch(ClientDataBranch.Main));
    }
    return pricingTab;
  },
);
