import { saveAs } from 'file-saver';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { Permission } from '@idearoom/types';
import { PortalInfoSchema } from '../constants/PortalInfoSchema';
import { SortDirection } from '../constants/SortDirection';
import { SystemGroups } from '../constants/SystemGroups';
import { FilterType, TableFilterType } from '../constants/Viewer';
import {
  closeOrderDetailDialog,
  deleteLeadSuccess,
  fetchOrderOwnerOptionsError,
  fetchOrderOwnerOptionsSuccess,
  fetchOrdersError,
  fetchOrdersSuccess,
  openOrderDetailDialog,
  openOrderDetailDialogFromUUID,
  OrderActionTypes,
  setOrderDealerSuccess,
  setOrderOwnerSuccess,
  setOrderStatusSuccess,
} from '../ducks/orders';
import { getGroupFilters } from '../selectors/getGroupFilters';
import { getGroupOrUndefined } from '../selectors/getGroupOrUndefined';
import { getSortProperties } from '../selectors/getSortProperties';
import { getSystemGroupOrderConfigurators } from '../selectors/getSystemGroupOrderConfigurators';
import { AppState } from '../types/AppState';
import { GroupFilter } from '../types/CustomFilter';
import { Group } from '../types/Group';
import { OrderOwner } from '../types/OrderOwner';
import { OrderRequest } from '../types/OrderRequest';
import { OrderResponse } from '../types/OrderResponse';
import { SortProperty } from '../types/SortProperty';
import { SystemGroupOrderConfigurator } from '../types/SystemGroupOrderConfigurator';
import { User } from '../types/User';
import { getSiteFromClientId } from '../utils/clientIdUtils';
import { isIdeaRoomGroup, isIdeaRoomUser } from '../utils/userUtils';
import { deletedLeadStatus, OrderStatusId } from '../constants/OrderStatus';
import { Order } from '../types/Order';
import { OrdersState } from '../types/OrdersState';
import { openNotificationDialog } from '../ducks/notificationDialog';
import { Dialogs } from '../constants/Dialogs';
import { openDialog } from '../ducks/dialogSlice';
import { getQueryParam } from '../utils/urlUtils';
import { Theme } from '../types/ViewerState';
import { Dealer } from '../types/Dealer';
import { getUrlWithUUID } from '../utils/vendorUtils';
import { QueryParamsToPassBy } from '../types/QueryParamsToPassBy';
import { unknownGroup } from '../constants/Group';
import { dealerApi } from '../services/dealerApi';
import { leadApi } from '../services/leadApi';
import { groupApi } from '../services/groupApi';
import {
  filterOrdersByDealer,
  filterOrdersByOwner,
  filterOrdersBySite,
  filterOrdersByStatus,
  filterOrdersBySubmitStatus,
  getFilterDateRange,
} from '../utils/orderUtils';
import { hasAdminPermissions } from '../utils/permissionUtils';
import { FilterMenuItems } from '../constants/FilterMenuItems';

/**
 * TODO: Make this work for N-configurators
 */
function* getOrderRequest(): Generator {
  const options: Record<string, Record<string, string>> = {};

  const sortProperties = (yield select(getSortProperties)) as SortProperty[];
  const sort = sortProperties
    .map(({ key, direction }) => `${direction === SortDirection.Desc ? '-' : ''}${key}`)
    .join(',');

  if (sort) {
    options.queryStringParameters = { sort };
  }

  let configurators: { configurator: string; vendor: string }[] = [];

  const group = (yield select(getGroupOrUndefined)) as Group;
  // IDEA_ROOM users are not members of a group not associated with any configurators.
  // Check if any are configured in AppState and use them if they are.
  if (isIdeaRoomGroup(group.groupId)) {
    const systemGroupOrderConfigurators = (yield select(
      getSystemGroupOrderConfigurators,
    )) as SystemGroupOrderConfigurator[];
    if (systemGroupOrderConfigurators) {
      configurators = systemGroupOrderConfigurators;
    }
  } else if (group && group.configurators) {
    configurators = group.configurators.map((c) => ({ configurator: c.key, vendor: c.vendor }));
  } else {
    throw new Error(`No configurators configured for group: ${group.groupName}`);
  }

  options.queryStringParameters = {
    ...options.queryStringParameters,
    configurators: configurators.map((c) => `${c.configurator}-${c.vendor}`).join(','),
  };

  const { filter, startDate, endDate } = (yield select(
    ({ orders: { date } }: AppState) => date,
  )) as AppState['orders']['date'];
  let start = startDate;
  let end = endDate;
  if (filter !== FilterMenuItems.Custom) {
    const { startDate: updatedStartDate, endDate: updatedEndDate } = getFilterDateRange(filter) || {};
    [start, end] = [updatedStartDate, updatedEndDate].map((date) => date?.toISOString() || '');
  }
  if (start && end) {
    options.queryStringParameters = {
      ...options.queryStringParameters,
      startDate: start,
      endDate: end,
    };
  }

  const orderRequest: OrderRequest = {
    options,
  };

  return orderRequest;
}

function* fetchOrders(): Generator {
  try {
    const orderRequest = (yield getOrderRequest()) as OrderRequest;

    const getOrdersFetch = (yield put(
      leadApi.endpoints.getLeads.initiate({
        leadRequest: orderRequest,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    getOrdersFetch.unsubscribe();

    const { data: { orders = [] } = {} } = (yield getOrdersFetch) as { data: OrderResponse };

    const { configurators = [], groupId = '' } = (yield select(getGroupOrUndefined)) as Group;
    const clientIds = !isIdeaRoomGroup(groupId) ? configurators.map((c) => `${c.key}-${c.vendor}`) : [];

    const getAllDealersFetch = (yield put(
      dealerApi.endpoints.getDealersByClientIds.initiate({
        clientIds,
        groupId,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    getAllDealersFetch.unsubscribe();

    const { data: { dealers = [] } = {} } = (yield getAllDealersFetch) as { data: { dealers: Dealer[] } };

    const getGroupFetch = (yield put(
      groupApi.endpoints.getGroup.initiate({
        groupId,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    getGroupFetch.unsubscribe();

    const { data: { group: { members: groupMembers = [] } = unknownGroup } = {} } = (yield getGroupFetch) as {
      data: { group: Group };
    };

    // Extract and assign any portalInfo
    const ordersWithPortalInfo = orders.map((order) => {
      const newOrder = { ...order };

      if (order?.portalInfo[PortalInfoSchema.OrderDealer]) {
        const { key: portalDealerKey, id: portalDealerId } = order.portalInfo[PortalInfoSchema.OrderDealer];
        const portalDealer = dealers.find((d) =>
          // Prefer id over key now that key can be changed
          portalDealerId ? d.id === portalDealerId : d.key === portalDealerKey,
        );

        if (portalDealerKey && portalDealer) {
          newOrder.orderDealerKey = portalDealer.key;
          newOrder.orderDealerName = portalDealer.name;

          const { queryParamsToPassBy: queryParams } = order;
          newOrder.orderDealerLink = `${getUrlWithUUID(
            portalDealer.dealerURL,
            order.uuid,
            (queryParams || []) as QueryParamsToPassBy[],
          )}#${order.hash}`;
        }
      }
      if (order?.portalInfo[PortalInfoSchema.OrderOwner]) {
        newOrder.ownerName = order.portalInfo[PortalInfoSchema.OrderOwner].name;
        newOrder.ownerEmail = order.portalInfo[PortalInfoSchema.OrderOwner].email;
      }
      if (order.salesRepEmail) {
        // Find a sales rep with the same email as the order
        const salesRep = groupMembers.find((m) => m.email === order.salesRepEmail);
        if (salesRep) {
          newOrder.salesRepName = salesRep.name;
        }
      }
      if (order?.portalInfo[PortalInfoSchema.OrderStatus]?.chip) {
        newOrder.orderStatusId = order.portalInfo[PortalInfoSchema.OrderStatus].id;
        newOrder.orderStatusName = order.portalInfo[PortalInfoSchema.OrderStatus].name;
        newOrder.orderStatusI18nKey = order.portalInfo[PortalInfoSchema.OrderStatus].i18nKey;
        newOrder.orderStatusFontColor = order.portalInfo[PortalInfoSchema.OrderStatus].chip.fontColor;
        newOrder.orderStatusBackgroundColor = order.portalInfo[PortalInfoSchema.OrderStatus].chip.backgroundColor;
      }

      return newOrder;
    });

    let filteredOrders = ordersWithPortalInfo;
    // Get the current user to filter on permissions and dealer if necessary
    const currentUser = (yield select(({ currentUser: { user } }: AppState) => user)) as User;
    const currentUserDealers = currentUser.dealers
      ? currentUser.dealers.filter((d) => d !== null && d !== undefined)
      : [];

    // Filter orders for SALES users to be only the orders owned by them
    if (
      currentUser.permissions.length === 0 ||
      (currentUser.permissions.length === 1 && currentUser.permissions[0] === Permission.Sales)
    ) {
      filteredOrders = filteredOrders.filter((order) => {
        const orderOwner = order.ownerEmail ? order.ownerEmail : order.salesRepEmail;
        return orderOwner ? orderOwner.toLowerCase() === currentUser.email.toLowerCase() : false;
      });
    } else if (currentUserDealers.length > 0 && !hasAdminPermissions(currentUser.permissions)) {
      filteredOrders = filteredOrders.filter((order) =>
        order.orderDealerKey || order.dealerKey
          ? currentUserDealers.includes(order.orderDealerKey || order.dealerKey)
          : false,
      );
    } else if (
      currentUser.permissions.includes(Permission.Manager) &&
      !hasAdminPermissions(currentUser.permissions) &&
      !currentUser.permissions.includes(Permission.SuperUser)
    ) {
      // If a manager does not have any dealers assigned, only show them unassigned leads
      filteredOrders = filteredOrders.filter((order) => !(order.orderDealerKey || order.dealerKey));
    }

    // If not an Idearoom admin user, filter out any "deleted" leads
    if (!isIdeaRoomUser(currentUser)) {
      filteredOrders = filteredOrders.filter(
        (order) => (order.orderStatusId || OrderStatusId.NewLead) !== OrderStatusId.Deleted,
      );
    }

    // Get custom order filters
    const groupFilters = (yield select(getGroupFilters)) as GroupFilter[];
    const existingGroupFilter = groupFilters.find((groupFilter) => groupFilter.groupId === groupId);
    if (existingGroupFilter) {
      const orderFilter = existingGroupFilter.tableFilters.find(
        (tableFilter) => tableFilter.tableFilterType === TableFilterType.Order,
      );

      // Filter by custom filters if applied
      if (orderFilter) {
        orderFilter.filters.forEach((filter) => {
          if (!filter.selectedFilterValues.some((selectedValue) => selectedValue.key === 'all')) {
            switch (filter.filterType) {
              case FilterType.Dealer:
                filteredOrders = filterOrdersByDealer(filteredOrders, filter);
                break;

              case FilterType.Owner:
                filteredOrders = filterOrdersByOwner(filteredOrders, filter);
                break;

              case FilterType.Site:
                filteredOrders = filterOrdersBySite(filteredOrders, filter);
                break;

              case FilterType.Status:
                filteredOrders = filterOrdersByStatus(filteredOrders, filter);
                break;

              case FilterType.SubmitStatus:
                filteredOrders = filterOrdersBySubmitStatus(filteredOrders, filter);
                break;

              default:
                break;
            }
          }
        });
      }
    }
    yield put(fetchOrdersSuccess(filteredOrders));

    const uuid = getQueryParam('uuid');
    if (uuid) {
      yield put(openOrderDetailDialogFromUUID(uuid));
    }
  } catch (error) {
    yield put(fetchOrdersError());
  }
}

function* watchFetchOrders(): Generator {
  yield takeLatest(OrderActionTypes.FETCH_ORDERS, fetchOrders);
}

function* fetchOrdersCsv(): Generator {
  const orderRequest = (yield getOrderRequest()) as OrderRequest;

  const getOrdersCsvFetch = (yield put(
    leadApi.endpoints.getLeadsCSV.initiate({
      leadRequest: orderRequest,
    }) as any,
  )) as QueryActionCreatorResult<any>;
  getOrdersCsvFetch.unsubscribe();

  const { data: blob } = (yield getOrdersCsvFetch) as { data: Blob };

  saveAs(blob, `download-${Date.now()}.csv`);
}

function* watchFetchOrdersCsv(): Generator {
  yield takeLatest(OrderActionTypes.FETCH_ORDERS_CSV, fetchOrdersCsv);
}

function* sendLeadAssignementEmail({ clientId, uuid, email, name, status }: any): Generator {
  const { groupId, configurators = [] } = (yield select(({ currentUser: { group } }: AppState) => group)) as Group;
  const { logoUrl = '', selectedTextColor = '' } = (yield select(({ viewer: { theme } }: AppState) => theme)) as Theme;
  const site = getSiteFromClientId(clientId, configurators) || '';

  const assignEmailFetch = (yield put(
    leadApi.endpoints.assignLead.initiate({
      groupId,
      clientId,
      uuid,
      emailAddress: email,
      status,
      site,
      logoUrl,
      buttonColor: selectedTextColor,
    }) as any,
  )) as QueryActionCreatorResult<any>;
  assignEmailFetch.unsubscribe();

  yield assignEmailFetch;

  yield call(toast.success, `A lead notification has been sent to ${name}.`);
}

// Order Dealer

function* setOrderDealer({ payload: { clientId, uuid, status, orderDealer } }: any): Generator {
  const updatePortalInfoFetch = (yield put(
    leadApi.endpoints.updatePortalInfo.initiate({
      clientId,
      uuid,
      key: PortalInfoSchema.OrderDealer,
      value: orderDealer,
    }) as any,
  )) as QueryActionCreatorResult<any>;
  updatePortalInfoFetch.unsubscribe();

  yield updatePortalInfoFetch;

  // Send an email to the dealer notifying them of the new lead
  const { settings } = (yield select(({ currentUser: { group } }: AppState) => group)) as Group;
  const { emailAddress: email, name } = orderDealer;
  if (settings && settings.dealerNotifications && email) {
    yield call(sendLeadAssignementEmail, { clientId, uuid, email, name, status });
  }
  yield put(setOrderDealerSuccess(clientId, uuid, orderDealer));
}

function* watchSetOrderDealer(): Generator {
  yield takeEvery(OrderActionTypes.SET_ORDER_DEALER, setOrderDealer);
}

// Order Owner

function* fetchOrderOwnerOptions({ payload: { groupId } }: any): Generator {
  try {
    const orderOwnerOptions: OrderOwner[] = [];

    // when logged in as an IDEA_ROOM user do not get options because they
    // wouldn't have the same group context
    if (groupId !== SystemGroups.IdeaRoom) {
      const getGroupFetch = (yield put(
        groupApi.endpoints.getGroup.initiate({
          groupId,
        }) as any,
      )) as QueryActionCreatorResult<any>;
      getGroupFetch.unsubscribe();

      const { data: { group = unknownGroup } = {} } = (yield getGroupFetch) as { data: { group: Group } };

      if (group.members) {
        orderOwnerOptions.push(
          ...group.members
            .filter((member) => !isIdeaRoomUser(member) && member.permissions.includes(Permission.Sales))
            .map(({ email, name }) => ({ email, name })),
        );
      }
    }

    yield put(fetchOrderOwnerOptionsSuccess(orderOwnerOptions));
  } catch (error) {
    yield put(fetchOrderOwnerOptionsError());
  }
}

function* watchFetchOrderOwnerOptions(): Generator {
  yield takeLatest(OrderActionTypes.FETCH_ORDER_OWNER_OPTIONS, fetchOrderOwnerOptions);
}

function* setOrderOwner({ payload: { clientId, uuid, status, orderOwner } }: any): Generator {
  const updatePortalInfoFetch = (yield put(
    leadApi.endpoints.updatePortalInfo.initiate({
      clientId,
      uuid,
      key: PortalInfoSchema.OrderOwner,
      value: orderOwner,
    }) as any,
  )) as QueryActionCreatorResult<any>;
  updatePortalInfoFetch.unsubscribe();

  yield updatePortalInfoFetch;

  // Send an email to the owner notifying them of the new lead
  const { settings } = (yield select(({ currentUser: { group } }: AppState) => group)) as Group;
  const { email, name } = orderOwner;
  if (settings && settings.salesRepNotifications && email) {
    yield call(sendLeadAssignementEmail, { clientId, uuid, email, name, status });
  }
  yield put(setOrderOwnerSuccess(clientId, uuid, orderOwner));
}

function* watchSetOrderOwner(): Generator {
  yield takeEvery(OrderActionTypes.SET_ORDER_OWNER, setOrderOwner);
}

// Order Status

function* setOrderStatus({ payload: { clientId, uuid, orderStatus } }: any): Generator {
  const updatePortalInfoFetch = (yield put(
    leadApi.endpoints.updatePortalInfo.initiate({
      clientId,
      uuid,
      key: PortalInfoSchema.OrderStatus,
      value: orderStatus,
    }) as any,
  )) as QueryActionCreatorResult<any>;
  updatePortalInfoFetch.unsubscribe();

  yield updatePortalInfoFetch;

  yield put(setOrderStatusSuccess(clientId, uuid, orderStatus));
}

function* watchSetOrderStatus(): Generator {
  yield takeEvery(OrderActionTypes.SET_ORDER_STATUS, setOrderStatus);
}

// Delete Lead

function* deleteLead(): Generator {
  // Get current user and currently selected order
  const currentUser = (yield select(({ currentUser: { user } }: AppState) => user)) as User;
  const currentOrder = (yield select(({ orders: { detailsState } }: AppState) => detailsState)) as Order;

  // Set order status to DELETED
  const updatePortalInfoFetch = (yield put(
    leadApi.endpoints.updatePortalInfo.initiate({
      clientId: currentOrder.clientId,
      uuid: currentOrder.uuid,
      key: PortalInfoSchema.OrderStatus,
      value: deletedLeadStatus,
    }) as any,
  )) as QueryActionCreatorResult<any>;
  updatePortalInfoFetch.unsubscribe();

  yield updatePortalInfoFetch;

  if (isIdeaRoomUser(currentUser)) {
    // If an Idearoom Admin, return as if a regular status change
    yield put(setOrderStatusSuccess(currentOrder.clientId, currentOrder.uuid, deletedLeadStatus));
  } else {
    // Update the orders in order to remove this order from the list
    yield put(deleteLeadSuccess(currentOrder.uuid));
  }
  // Close the details dialog
  yield put(closeOrderDetailDialog());
}

function* watchDeleteLead(): Generator {
  yield takeEvery(OrderActionTypes.DELETE_LEAD, deleteLead);
}

function* openOrderDetailDialogFromUuid({ payload: { uuid } }: any): Generator {
  const { orders } = (yield select(({ orders: orderState }: AppState) => orderState)) as OrdersState;

  // Check if an order with the uuid exists in the list of orders
  // if so open the order details dialog with that order
  // otherwise open a snackbar saying the order was not found
  const order = orders.find((o) => o.uuid === uuid);
  if (order) {
    yield put(openOrderDetailDialog(order));
  } else {
    const notificationContent = `
      <p>The building you are trying to view is not available to you in SalesView.</p>
      It may have been deleted, or you may not have access.
    `;
    yield put(openNotificationDialog('', notificationContent));
    yield put(openDialog({ dialog: Dialogs.Notification }));
  }
}

function* watchOpenOrderDetailDialogFromUuid(): Generator {
  yield takeEvery(OrderActionTypes.OPEN_DETAILS_DIALOG_FROM_UUID, openOrderDetailDialogFromUuid);
}

export function* orderSaga(): Generator {
  yield all([
    watchFetchOrders(),
    watchFetchOrdersCsv(),
    watchFetchOrderOwnerOptions(),
    watchSetOrderDealer(),
    watchSetOrderOwner(),
    watchSetOrderStatus(),
    watchDeleteLead(),
    watchOpenOrderDetailDialogFromUuid(),
  ]);
}
