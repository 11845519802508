import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dialogs } from '../constants/Dialogs';
import { closeDialog as closeDialogFunc } from '../ducks/dialogSlice';
import { AppState } from '../types/AppState';
import { Dialog } from './Dialog';
import { I18nKeys } from '../constants/I18nKeys';

interface StateProps {
  notificationTitle: string | undefined;
  notificationContent: string | undefined;
}

interface DispatchProps {
  closeDialog(): void;
}

type Props = StateProps & DispatchProps;

const NotificationDialogComponent: React.FC<Props> = ({
  closeDialog,
  notificationTitle,
  notificationContent,
}: Props) => {
  const { t } = useTranslation();

  let title = notificationTitle;
  if (notificationTitle && !notificationTitle.includes(' ') && notificationTitle.includes('-')) {
    title = t(notificationTitle);
  }

  let content = notificationContent;
  if (notificationContent && !notificationContent.includes(' ') && notificationContent.includes('-')) {
    content = t(notificationContent);
  }

  return (
    <Dialog dialogKey={Dialogs.Notification}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          dangerouslySetInnerHTML={{
            __html: content || '',
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {t(I18nKeys.DialogGotItButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ notificationDialog: { notificationTitle, notificationContent } }: AppState): StateProps => ({
  notificationTitle,
  notificationContent,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  closeDialog: (): void => {
    dispatch(closeDialogFunc());
  },
});

export const NotificationDialog = connect(mapStateToProps, mapDispatchToProps)(NotificationDialogComponent);
