import { Box, Stack, Typography, Slide } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchInput } from './SearchInput';
import { useAppSelector } from '../hooks';
import { useClientUpdatePricingRepo } from '../hooks/useClientUpdatePricingRepo';
import { ClientUpdateCategory, ComponentCategoryItemWithConditions } from '../types/PricingClientUpdate';
import {
  filterClientUpdateCategories,
  filterComponentCategoryItems,
  getClientUpdateSearchCountText,
  groupComponentCategoryItems,
} from '../utils/pricingClientUpdateUtils';
import { PricingTab } from '../constants/Pricing';
import { ComponentCategoryKey, SizeBasedCategoryKey } from '../constants/ClientUpdateCategoryKey';

export const PRICING_CLIENT_UPDATE_SEARCH_HEIGHT = 60;

const useStyles = makeStyles(() => ({
  searchSection: {
    width: '100%',
    'align-items': 'center',
  },
  searchField: {
    width: '250px',
  },
}));

type Props = {
  searchValue?: string;
  setSearchValue: (value: string) => void;
};

export const PricingClientUpdateSearch: React.FC<Props> = ({ searchValue = '', setSearchValue }: Props) => {
  const classes = useStyles();

  const pricingTab = useAppSelector((state) => state.viewer.selectedPricingTabId);
  const componentCategoryKey = useAppSelector((state) => state.pricing.component.selectedCategoryKey);
  const sizeBasedCategoryKey = useAppSelector((state) => state.pricing.sizeBased.selectedCategoryKey);

  const {
    clientUpdateCategoriesList: categories,
    isLoadingClientUpdateCategoriesList,
    componentCategoryItemsWithConditions,
    isLoadingComponentCategoryItemsWithConditions,
    sizeBasedCategoryPricingSheets,
  } = useClientUpdatePricingRepo({
    useClientUpdateCategories: true,
    useComponentCategoryItemsWithConditions: true,
    useSizeBasedCategoryPricingSheets: true,
  });

  const containerRef = React.useRef<HTMLDivElement>(null);

  const [items, setItems] = useState<ComponentCategoryItemWithConditions[] | undefined>(undefined);
  const [filteredItems, setFilteredItems] = useState<
    ClientUpdateCategory[] | ComponentCategoryItemWithConditions[] | undefined
  >(undefined);
  const [category, setCategory] = useState<ComponentCategoryKey | SizeBasedCategoryKey | undefined>(undefined);

  const { t } = useTranslation();

  useEffect(() => {
    if (pricingTab === PricingTab.Component) {
      setCategory(componentCategoryKey);
      setItems(
        groupComponentCategoryItems(
          componentCategoryKey as ComponentCategoryKey,
          componentCategoryItemsWithConditions || [],
        ),
      );
    } else {
      setCategory(sizeBasedCategoryKey);
    }
  }, [
    componentCategoryKey,
    sizeBasedCategoryKey,
    pricingTab,
    componentCategoryItemsWithConditions,
    sizeBasedCategoryPricingSheets,
  ]);

  useEffect(() => {
    setFilteredItems(
      category
        ? filterComponentCategoryItems(searchValue, items)
        : filterClientUpdateCategories(searchValue, categories, t),
    );
  }, [category, searchValue, items, categories, t]);

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={3}
      className={classes.searchSection}
    >
      <SearchInput
        classes={classes}
        searchTerm={searchValue}
        onChange={(val) => setSearchValue(val)}
        onClearClick={() => setSearchValue('')}
        startAdornment={<SearchIcon />}
        variant="outlined"
        size="small"
        margin="dense"
      />
      <Box ref={containerRef} style={{ overflow: 'hidden', flex: 1 }}>
        <Slide
          direction="right"
          in={
            category
              ? !!items && pricingTab === PricingTab.Component && !isLoadingComponentCategoryItemsWithConditions
              : !!categories && !isLoadingClientUpdateCategoriesList
          }
          container={containerRef.current}
        >
          <Typography variant="subtitle1" component="div" color="text.secondary">
            {getClientUpdateSearchCountText(searchValue, pricingTab || '', category, filteredItems, items, categories)}
          </Typography>
        </Slide>
      </Box>
    </Stack>
  );
};
