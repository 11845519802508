import React from 'react';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PricingSheetGrid } from './PricingSheetGrid';
import { PricingSheetRegion, PriceColumnsByGroupId } from '../types/Region';
import { PricingSheet as PricingSheetType } from '../types/PricingSheet';
import { CellMetadata, ClientDataTableRowDiff } from '../types/ClientData';
import { GridViewType } from '../constants/GridViewType';
import { useAppSelector } from '../hooks';
import { getConfiguratorFromClientId } from '../utils/clientIdUtils';
import { PricingTab } from '../constants/Pricing';
import { I18nKeys } from '../constants/I18nKeys';
import { hasDefaultRegionFunc, regionHasPrices } from '../utils/pricingSheetUtils';
import { SHEDVIEW } from '../constants/ClientId';

const useStyles = makeStyles(() => ({
  accordion: {
    background: 'none',
    marginBottom: '16px',
    '&$expanded': {
      display: 'flex',
      flexDirection: 'column',
    },
    '&:before': {
      height: '0px',
    },
  },
  regionTitle: {
    margin: '0px 8px 0px 0px',
    fontWeight: '700',
    fontSize: '16px',
  },
  regionDiffs: {
    fontWeight: '400',
    fontSize: '14px',
    alignSelf: 'center',
  },
  accordionSummary: {
    padding: '0px',
    flexDirection: 'row-reverse',
  },
  accordionDetails: {
    padding: '0px',
    marginTop: '16px',
  },
}));

export const PricingSheetAccordion: React.FC<{
  index: number;
  regions: PricingSheetRegion[];
  defaultPriceColumn: PriceColumnsByGroupId;
  defaultExpanded: boolean;
  expanded: boolean;
  handleExpand: (index: number) => void;
  gridViewType: GridViewType;
  pricingSheet: PricingSheetType | undefined;
  cellMetadata: CellMetadata[];
  diffs: { table: string; changes: ClientDataTableRowDiff[] }[];
  subtitleTextPerRegion: { [rowId: string]: string };
}> = ({
  index,
  regions,
  defaultPriceColumn,
  defaultExpanded,
  expanded = false,
  handleExpand,
  gridViewType,
  pricingSheet,
  cellMetadata,
  diffs,
  subtitleTextPerRegion,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const region = regions[index];
  const { selectedPricingTabId: pricingTab = '' } = useAppSelector((state) => state.viewer);
  const { clientId } = useAppSelector((state) => state.clientData);
  const configurator = getConfiguratorFromClientId(clientId);
  const hasDefaultRegion = hasDefaultRegionFunc(regions, defaultPriceColumn);
  const { prices } = pricingSheet || {};
  const isHiddenRegion = !!prices?.every(({ hidden }) => hidden[region.regionKey]);

  const showAddSize = index === 0 && configurator?.key === SHEDVIEW && pricingTab === PricingTab.Base;

  return (
    <Accordion
      key={region.rowId}
      disableGutters
      elevation={0}
      square
      defaultExpanded={defaultExpanded}
      classes={{ root: classes.accordion }}
      expanded={expanded}
      onChange={() => handleExpand(index)}
    >
      <AccordionSummary
        className={classes.accordionSummary}
        style={{ minHeight: '24px' }}
        expandIcon={<ExpandMoreIcon style={{ fontSize: '24px' }} />}
      >
        <Typography className={classes.regionTitle}>{region.label}</Typography>
        {(hasDefaultRegion || isHiddenRegion) && (
          <Typography className={classes.regionDiffs}>{subtitleTextPerRegion[region.rowId]}</Typography>
        )}
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {regionHasPrices(pricingSheet?.prices, region) ? (
          <PricingSheetGrid
            gridViewType={gridViewType}
            regions={[region]}
            defaultPriceColumn={defaultPriceColumn}
            hasDefaultRegion={hasDefaultRegion}
            pricingSheet={pricingSheet}
            cellMetadata={cellMetadata}
            diffs={diffs}
            showAddSize={showAddSize}
          />
        ) : (
          // According to design, if region has no prices, a message is shown with the region name
          <Typography>{t(I18nKeys.PricingBaseSheetHasNoPricing, { region: region.label })}</Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
