import React, { useEffect, useState } from 'react';
import { CircularProgress, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PricingAttributes } from './PricingAttributes';
import { PricingSheet } from './PricingSheet';
import { Loading } from './Loading';
import { PricingSheetEmptyLayout } from './PricingSheetEmptyLayout';
import { PricingSheet as PricingSheetType } from '../types/PricingSheet';
import { PricingSheetRegion, PriceColumnsByGroupId } from '../types/Region';
import { GridViewType } from '../constants/GridViewType';
import { CellMetadata, ClientDataTableRowDiff } from '../types/ClientData';
import { useAppSelector } from '../hooks';
import { PricingSheetNameDialog } from './PricingSheetNameDialog';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'stretch',
    height: '100%',
    width: '100%',
    overflowX: 'auto',
  },
  two: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: alpha('#fff', 0.5),
    zIndex: 100,
  },
  wrapper: {
    position: 'absolute',
    top: '40%',
    left: '50%',
  },
});

type Props = {
  gridViewType: GridViewType;
  selectedPricingSheetId?: string;
  selectedPricingSheet?: PricingSheetType;
  pricingSheets: PricingSheetType[];
  errorLoadingPricingSheets: boolean;
  isFetchingPricingSheets: boolean;
  isLoadingPricingSheets: boolean;
  isLoadingPricingSheetPrices: boolean;
  regions: PricingSheetRegion[];
  defaultPriceColumn: PriceColumnsByGroupId;
  cellMetadata: CellMetadata[];
  cellMetadataDiff: CellMetadata[];
  pricingDiffs: {
    table: string;
    changes: ClientDataTableRowDiff[];
  }[];
};

export const PricingSheetView: React.FC<Props> = ({
  gridViewType,
  selectedPricingSheetId,
  selectedPricingSheet,
  pricingSheets,
  errorLoadingPricingSheets,
  isFetchingPricingSheets,
  isLoadingPricingSheets,
  isLoadingPricingSheetPrices,
  regions,
  defaultPriceColumn,
  cellMetadata,
  cellMetadataDiff,
  pricingDiffs,
}: Props) => {
  const classes = useStyles();

  const { isCreatingBranch } = useAppSelector((state) => state.clientData);

  const [accordionsState, setAccordionsState] = useState<{
    [pricingSheetId: string]: { [index: number]: boolean };
  }>({});

  // When first selecting a pricing sheet, expand the first accordion by default
  useEffect(() => {
    if (!selectedPricingSheetId || accordionsState[selectedPricingSheetId]) return;
    setAccordionsState((prevAccordionsState) => ({
      ...prevAccordionsState,
      [selectedPricingSheetId]: { 0: true },
    }));
  }, [selectedPricingSheetId]);

  const onAccordionChange = (index: number): void => {
    const pricingSheetId = selectedPricingSheet?.id || '';
    setAccordionsState((prevAccordionsState) => ({
      ...prevAccordionsState,
      [pricingSheetId]: {
        ...(prevAccordionsState[pricingSheetId] || {}),
        [index]: !prevAccordionsState[pricingSheetId]?.[index],
      },
    }));
  };

  if (pricingSheets.length === 0) {
    if (!errorLoadingPricingSheets && isLoadingPricingSheets) {
      return <Loading />;
    }

    if (!errorLoadingPricingSheets && !isLoadingPricingSheets && !isFetchingPricingSheets) {
      return <PricingSheetEmptyLayout />;
    }
  }

  return (
    <div className={classes.root}>
      <PricingAttributes
        selectedPricingSheet={selectedPricingSheet}
        pricingSheets={pricingSheets}
        cellMetadataDiff={cellMetadataDiff}
        pricingDiffs={pricingDiffs}
      />
      <PricingSheet
        gridViewType={gridViewType}
        selectedPricingSheetId={selectedPricingSheetId}
        pricingSheets={pricingSheets}
        selectedPricingSheet={selectedPricingSheet}
        isLoadingPricingSheetPrices={isLoadingPricingSheetPrices}
        regions={regions}
        defaultPriceColumn={defaultPriceColumn}
        cellMetadata={cellMetadata}
        pricingDiffs={pricingDiffs}
        accordionsState={accordionsState}
        onAccordionChange={onAccordionChange}
      />
      {((!isLoadingPricingSheets && isFetchingPricingSheets && pricingSheets.length === 0) || isCreatingBranch) && (
        <div className={classes.two}>
          <div className={classes.wrapper}>
            <CircularProgress color="primary" />
          </div>
        </div>
      )}
      <PricingSheetNameDialog selectedPricingSheet={selectedPricingSheet} />
    </div>
  );
};
