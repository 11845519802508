import { createApi } from '@reduxjs/toolkit/query/react';
import { API_NAMES } from '../constants/App';
import { amplifyAPIBaseQuery, getRequestHeader } from '../utils/apiUtils';
import { CurrentUserData } from '../types/User';
import { UserCacheTagType } from '../constants/User';
import { GroupCacheTagType } from '../constants/Group';

export const userApi = createApi({
  reducerPath: 'userApi',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: [
    UserCacheTagType.AllUsers,
    UserCacheTagType.CurrentUserData,
    UserCacheTagType.CurrentUserGroups,
    UserCacheTagType.CurrentUserConfigurators,
    UserCacheTagType.CurrentUserPreferences,
    UserCacheTagType.ValidUser,
    GroupCacheTagType.Group,
  ],
  baseQuery: amplifyAPIBaseQuery({
    apiName: API_NAMES.API_PUBLIC,
    baseUrl: '/v1/internal/user',
  }),
  endpoints: (builder) => ({
    /**
     * Get current users data for the current group
     */
    getCurrentUserData: builder.query<CurrentUserData, { groupId: string }>({
      query: ({ groupId }) => ({
        url: `/me/data/${groupId}`,
        method: 'get',
      }),
      providesTags: (result, error, { groupId }) =>
        result ? [{ type: UserCacheTagType.CurrentUserData, id: groupId }] : [],
    }),

    /**
     * Gets the current users preferences
     */
    getCurrentUserPreferences: builder.query<{ preferences: any }, void>({
      query: () => ({
        url: `/me/preferences`,
        method: 'get',
      }),
      providesTags: (result) => (result ? [UserCacheTagType.CurrentUserPreferences] : []),
    }),

    /**
     * Gets a list of all users and the groups they belong to
     */
    getAllUsers: builder.query({
      query: () => ({
        url: `s/groups`,
        method: 'get',
      }),
      providesTags: (result) => (result ? [UserCacheTagType.AllUsers] : []),
    }),

    /**
     * Add or update user preferences
     */
    saveUserPreferences: builder.mutation<{ preferences: any }, { preferences: any }>({
      query: ({ preferences }) => ({
        url: `/me/preferences`,
        method: 'post',
        init: {
          body: { preferences },
        },
      }),
      invalidatesTags: [UserCacheTagType.CurrentUserPreferences],
    }),

    /**
     * Add an event for the current user.
     *
     * @param {string} groupId
     * @param {string | undefined} clientId
     * @param {string | undefined} eventCategory
     * @param {string} eventType
     */
    addUserEvent: builder.mutation<
      void,
      { groupId: string; clientId: string | undefined; eventCategory: string | undefined; eventType: string }
    >({
      query: ({ groupId, clientId, eventCategory, eventType }) => ({
        url: `/me/events`,
        method: 'post',
        init: {
          body: { groupId, clientId, eventCategory, eventType },
        },
      }),
    }),

    /**
     * Validates the given username.
     * Checks for:
     * - Existence in current group
     * - Existence in current group but another dealer
     * - Existence in another group
     */
    validateUser: builder.query<{ message: string }, { username: string; groupId: string }>({
      query: ({ username, groupId }) => ({
        url: `/validate`,
        method: 'post',
        init: {
          body: { username, groupId },
        },
      }),
      providesTags: (result) => (result ? [{ type: UserCacheTagType.ValidUser }] : []),
    }),

    /**
     * Delete the specified user
     */
    deleteUser: builder.mutation<void, { groupId: string; username: string }>({
      query: ({ groupId, username }) => ({
        url: `/${groupId}/${username}`,
        method: 'del',
      }),
      invalidatesTags: (result, error, { groupId }) => [{ type: GroupCacheTagType.Group, id: groupId }],
    }),

    /**
     * Add user to group
     */
    addUserToGroup: builder.mutation<
      void,
      {
        groupId: string;
        email: string;
        firstName: string;
        lastName: string;
        phone: string;
        permissions: string[];
        dealers: string[];
      }
    >({
      query: ({ groupId, email, firstName, lastName, phone, permissions, dealers }) => ({
        url: `/group`,
        method: 'post',
        init: {
          body: {
            groupId,
            email,
            firstName,
            lastName,
            phone,
            permissions,
            dealers,
          },
        },
      }),
      invalidatesTags: (result, error, { groupId }) => [{ type: GroupCacheTagType.Group, id: groupId }],
    }),

    /**
     * Add bulk members to group
     */
    addBulkMembersToGroup: builder.mutation<
      void,
      {
        groupId: string;
        emails: string;
        permissions: string[];
        dealers: string[];
      }
    >({
      query: ({ groupId, emails, permissions, dealers }) => ({
        url: `/${groupId}/bulk`,
        method: 'post',
        init: {
          body: {
            emails,
            permissions,
            dealers,
          },
        },
      }),
      invalidatesTags: (result, error, { groupId }) => [{ type: GroupCacheTagType.Group, id: groupId }],
    }),

    /**
     * update user in group
     */
    updateUserInGroup: builder.mutation<
      void,
      {
        groupId: string;
        email: string;
        firstName: string;
        lastName: string;
        phone: string;
        permissions?: string[];
        dealers?: string[];
      }
    >({
      query: ({ groupId, email, firstName, lastName, phone, permissions, dealers }) => ({
        url: `/group`,
        method: 'put',
        init: {
          body: { groupId, email, firstName, lastName, phone, permissions, dealers },
        },
      }),
      invalidatesTags: (result, error, { groupId }) => [{ type: GroupCacheTagType.Group, id: groupId }],
    }),

    /**
     * Update user permissions
     */
    updateUserPermissions: builder.mutation<
      void,
      {
        groupId: string;
        username: string;
        permissions: string[];
      }
    >({
      query: ({ groupId, username, permissions }) => ({
        url: `/group/permissions`,
        method: 'put',
        init: {
          body: { groupId, username, permissions },
        },
      }),
      invalidatesTags: (result, error, { groupId }) => [{ type: GroupCacheTagType.Group, id: groupId }],
    }),

    /**
     * Send user invitation email
     */
    sendUserInvitation: builder.mutation<
      void,
      {
        username: string;
        groupId: string;
      }
    >({
      query: ({ username, groupId }) => ({
        url: `/send-invitation`,
        method: 'post',
        init: {
          headers: getRequestHeader({ groupId }),
          body: { username },
        },
      }),
    }),

    /**
     * Resend user invitation email
     */
    resendUserInvitation: builder.mutation<
      void,
      {
        username: string;
        groupId: string;
      }
    >({
      query: ({ username, groupId }) => ({
        url: `/resend-invitation`,
        method: 'post',
        init: {
          headers: getRequestHeader({ groupId }),
          body: { username },
        },
      }),
    }),

    /**
     * Reset user password
     */
    resetUserPassword: builder.mutation<
      void,
      {
        username: string;
        groupId: string;
      }
    >({
      query: ({ username, groupId }) => ({
        url: `/reset-password`,
        method: 'post',
        init: {
          headers: getRequestHeader({ groupId }),
          body: { username },
        },
      }),
    }),

    /**
     * Initiate impersonation of a user
     *
     */
    initiateImpersonation: builder.mutation<
      string,
      {
        username: string;
        usernameToImpersonate: string;
      }
    >({
      query: ({ username, usernameToImpersonate }) => ({
        url: `/initiate-impersonate`,
        method: 'post',
        init: {
          body: { username, usernameToImpersonate },
        },
      }),
    }),

    /**
     * Validate impersonation of a user
     *
     * @returns { username: string, impersonatedUser: string }
     */
    validateImpersonation: builder.query<
      {
        authenticationResult: {
          AccessToken: string;
          IdToken: string;
          RefreshToken: string;
        };
      },
      {
        code: string;
        username: string;
        session: string;
      }
    >({
      query: ({ code, username, session }) => ({
        url: `/validate-impersonate`,
        method: 'post',
        init: {
          body: { code, username, session },
        },
      }),
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetCurrentUserDataQuery,
  useGetCurrentUserPreferencesQuery,
  useSaveUserPreferencesMutation,
  useAddUserEventMutation,
  useValidateUserQuery,
  useDeleteUserMutation,
  useAddUserToGroupMutation,
  useAddBulkMembersToGroupMutation,
  useUpdateUserInGroupMutation,
  useUpdateUserPermissionsMutation,
  useSendUserInvitationMutation,
  useResendUserInvitationMutation,
  useResetUserPasswordMutation,
  useInitiateImpersonationMutation,
  useValidateImpersonationQuery,
} = userApi;
