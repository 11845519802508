/* eslint-disable react-hooks/rules-of-hooks */
import { Permission } from '@idearoom/types';
import { useAppSelector } from '../hooks';
import { OnlyExistingKeys } from '../types/Repo';
import { unknownUser } from '../types/User';
import { useGetAllUsersQuery } from '../services/userApi';
import { isIdeaRoomGroup, isIdeaRoomUser } from '../utils/userUtils';
import { unknownGroup } from '../constants/Group';
import { GroupMember } from '../types/GroupMember';

/**
 * Using constant empty var references so the hook doesn't generate new references on every render
 * Allowing us to use the returned values from this hook inside useEffect params.
 */
const EMPTY_USERS_REFERENCE: ReadonlyArray<GroupMember> = [];

type UseUserRepoReturn<UseAllUsers> = OnlyExistingKeys<{
  allUsers: UseAllUsers extends true
    ? (GroupMember & { groups: { id: string; name: string; permissions: Permission[] }[] })[]
    : never;
  isLoadingAllUsers: UseAllUsers extends true ? boolean : never;
}>;

/**
 * Hook for fetching group data.
 * The useData... params are used so we don't end up subscribing this component to unnecessary queries.
 *
 * @param useData param used to specify the data the component needs to fetch.
 */
export const useUserRepo = <UseAllUsers extends boolean = false>({
  useAllUsers,
}: {
  useAllUsers?: UseAllUsers;
} = {}): UseUserRepoReturn<UseAllUsers> => {
  const { group = unknownGroup, user = unknownUser } = useAppSelector((state) => state.currentUser);

  const result: any = {};
  if (useAllUsers) {
    const { data: allUsers = EMPTY_USERS_REFERENCE, isFetching: isLoadingAllUsers } = useGetAllUsersQuery(
      {},
      {
        skip: !isIdeaRoomGroup(group.groupId) || !isIdeaRoomUser(user),
        refetchOnFocus: false,
      },
    );
    result.allUsers = allUsers;
    result.isLoadingAllUsers = isLoadingAllUsers;
  }

  return result;
};
