import React, { useLayoutEffect, useState } from 'react';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { formValueSelector } from 'redux-form';
import { Integration, IntegrationName, IntegrationStatus } from '@idearoom/types';
import { closeDialog as closeDialogFunc, openDialog } from '../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { Dialogs } from '../constants/Dialogs';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { IntegrationOptions } from '../types/DialogState';
import { FormData as AdyenFormData, IntegrationsAdyenForm } from './IntegrationsDialogAdyenForm';
import { IntegrationsFormFields } from '../constants/FormFields';
import { AppState } from '../types/AppState';
import { Forms } from '../constants/Forms';
import { putIntegration } from '../ducks/integrationSlice';
import { LoadingButton } from './LoadingButton';

const useStyles = makeStyles(() => ({
  dialogTitle: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '23.4px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#000000DE',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  dialogSubtitle: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#000000DE',
    marginBottom: '16px',
  },
  dialogActions: {
    padding: '0px 16px 16px 16px',
  },
  deactivateButton: {
    color: '#F44336',
  },
}));

const getFormData = (appState: AppState, integration: Integration) => {
  switch (integration.name) {
    case IntegrationName.Adyen: {
      const formSelector = formValueSelector(Forms.IntegrationsAdyenForm);
      const formAddress1 = formSelector(appState, IntegrationsFormFields.Address1);
      const formAddress2 = formSelector(appState, IntegrationsFormFields.Address2);
      const formCity = formSelector(appState, IntegrationsFormFields.City);
      const formCountry = formSelector(appState, IntegrationsFormFields.Country);
      const formPhone = formSelector(appState, IntegrationsFormFields.Phone);
      const formPostalCode = formSelector(appState, IntegrationsFormFields.PostalCode);
      const formState = formSelector(appState, IntegrationsFormFields.State);
      const { address, phoneNumber } = integration.data?.store || {};

      const formData = {
        [IntegrationsFormFields.Address1]: formAddress1 || address?.address1 || '',
        [IntegrationsFormFields.Address2]: formAddress2 || address?.address2 || '',
        [IntegrationsFormFields.City]: formCity || address?.city || '',
        [IntegrationsFormFields.Country]: formCountry || address?.country || '',
        [IntegrationsFormFields.Phone]: formPhone || phoneNumber || '',
        [IntegrationsFormFields.PostalCode]: formPostalCode || address?.postalCode || '',
        [IntegrationsFormFields.State]: formState || address?.state || '',
      } as AdyenFormData;
      return formData;
    }
    default:
      return undefined;
  }
};

const IntegrationsForm: React.FC<{ integration?: Integration; formData: AdyenFormData | undefined }> = ({
  integration,
  formData,
}) => {
  const appState = useAppSelector((state: AppState) => state);
  if (!integration) return <div />;

  switch (integration.name) {
    case IntegrationName.Adyen: {
      const initialValues = formData || getFormData(appState, integration);
      return <IntegrationsAdyenForm initialValues={initialValues} />;
    }
    default:
      return <div />;
  }
};

const DeactivableIntegrations = [IntegrationName.Adyen];

export const IntegrationsDialog: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [dialogTitle, setDialogTitle] = useState<string | undefined>(undefined);
  const [okText, setOkText] = useState<string | undefined>(undefined);
  const [setupInstructionsText, setSetupInstructionsText] = useState<string | undefined>(undefined);
  const [deactivateButtonVisible, isDeactivateButtonVisible] = useState<boolean>();
  const { options = {} } = useAppSelector((state) => state.dialog);
  const { updatingIntegration } = useAppSelector((state) => state.integration);
  const { integration, formData } = options as IntegrationOptions;
  const onClickOk = () => {
    dispatch(putIntegration({ integration }));
  };

  useLayoutEffect(() => {
    if (integration) {
      const isAlreadySetup = [IntegrationStatus.Connected, IntegrationStatus.Deactivated].includes(integration.status);
      if (isAlreadySetup) {
        isDeactivateButtonVisible(
          DeactivableIntegrations.includes(integration.name) && integration.status === IntegrationStatus.Connected,
        );
        setOkText(t(I18nKeys.DialogSaveButton));
      } else {
        setOkText(undefined);
        isDeactivateButtonVisible(false);
      }

      setDialogTitle(
        t(I18nKeys.IntegrationsDialogTitle, {
          integration: t(I18nKeys.IntegrationsName, { context: integration.name }),
          context: isAlreadySetup ? 'settings' : 'setup',
        }),
      );

      setSetupInstructionsText(
        t(I18nKeys.IntegrationsDialogSetupInstructions, { context: integration.name, defaultValue: undefined }),
      );
    }
  }, [setDialogTitle, setOkText, integration, t, isDeactivateButtonVisible]);

  return (
    <Dialog dialogKey={Dialogs.Integations}>
      <DialogTitle className={classes.dialogTitle}>{dialogTitle}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {setupInstructionsText && (
          <Typography
            className={classes.dialogSubtitle}
            component="span"
            dangerouslySetInnerHTML={{
              __html: setupInstructionsText,
            }}
          />
        )}
        <IntegrationsForm integration={integration} formData={formData} />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {deactivateButtonVisible && (
          <>
            <Button
              onClick={(): void => {
                dispatch(closeDialogFunc());
                dispatch(
                  openDialog({
                    dialog: Dialogs.IntegationsDeactivate,
                    options: { integration, formData },
                  }),
                );
              }}
              className={classes.deactivateButton}
            >
              {t(I18nKeys.DialogDeactivateButton)}
            </Button>
            <Box sx={{ flexGrow: 1 }} />
          </>
        )}

        <Button
          onClick={(): void => {
            dispatch(closeDialogFunc());
          }}
          color="primary"
        >
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        {okText && (
          <LoadingButton loading={updatingIntegration} color="primary" onClick={onClickOk}>
            {okText}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
