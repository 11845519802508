import { Grid2 as Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../hooks';
import { PricingComponentEditDialog } from './PricingComponentEditDialog';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { notifyOfDataChange } from '../utils/pricingUtils';
import { getClientIdFromClientSupplier } from '../utils/clientIdUtils';
import { unknownGroup } from '../constants/Group';
import { LocalStorage } from '../constants/LocalStorage';
import { PricingClientUpdatePreviewDialog } from './PricingClientUpdatePreviewDialog';
import { PricingPublishDialog } from './PricingPublishDialog';
import { PricingPublishResultDialog } from './PricingPublishResultDialog';
import { PricingClientUpdateHeader } from './PricingClientUpdateHeader';
import { PricingClientUpdateCategories } from './PricingClientUpdateCategories';
import { PricingComponentCategoryItems } from './PricingComponentCategoryItems';
import { I18nKeys } from '../constants/I18nKeys';
import { ClientDataType } from '../constants/ClientDataType';
import { setClientDataType } from '../ducks/clientDataSlice';
import { PricingTab } from '../constants/Pricing';
import { ClientUpdateCategoryKey } from '../types/PricingClientUpdate';
import { useClientUpdatePricingRepo } from '../hooks/useClientUpdatePricingRepo';
import { PricingSizeBasedPricingSheets } from './PricingSizeBasedPricingSheets';

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    height: 'calc(100% - 100px)',
  },
}));

export const PricingClientUpdate: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { selectedCategoryKey: componentCategoryKey } = useAppSelector((state) => state.pricing.component);
  const { selectedCategoryKey: sizeBasedCategoryKey } = useAppSelector((state) => state.pricing.sizeBased);
  const pricingTab = useAppSelector((state) => state.viewer.selectedPricingTabId);
  const { clientDataType } = useAppSelector((state) => state.clientData);
  const clientId = useAppSelector((state) =>
    getClientIdFromClientSupplier(state?.viewer?.selectedTabId || state?.viewer?.selectedClientId || ''),
  );
  const { group: { groupId } = unknownGroup } = useAppSelector((state) => state?.currentUser);

  const [categoryKey, setCategoryKey] = React.useState<ClientUpdateCategoryKey | undefined>(undefined);
  const [headerHeight, setHeaderHeight] = React.useState(0);

  if (clientDataType !== ClientDataType.Supplier) {
    dispatch(setClientDataType(ClientDataType.Supplier));
  }

  const { clientUpdateBranchMergeCommit } = useClientUpdatePricingRepo({
    useClientUpdateBranchMergeCommit: true,
  });

  const [searchValue, setSearchValue] = React.useState('');

  useEffect(() => {
    setCategoryKey(pricingTab === PricingTab.Component ? componentCategoryKey : sizeBasedCategoryKey);
  }, [pricingTab, setCategoryKey, componentCategoryKey, sizeBasedCategoryKey]);

  useEffect(() => {
    notifyOfDataChange({
      groupId,
      clientId,
      latestCommit: clientUpdateBranchMergeCommit,
      key:
        pricingTab === PricingTab.Component
          ? LocalStorage.LastPricingComponentMergeCommit
          : LocalStorage.LastPricingSizeBasedMergeCommit,
      dispatch,
      t,
    });
  }, [clientUpdateBranchMergeCommit, dispatch, t, groupId, clientId, pricingTab]);

  return (
    <div className={classes.root}>
      <Grid container direction="row" style={{ height: '100%' }}>
        {(!categoryKey || pricingTab !== PricingTab.SizeBased) && (
          <PricingClientUpdateHeader
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            headerHeight={headerHeight}
            setHeaderHeight={setHeaderHeight}
          />
        )}
        <Grid
          size={{ xs: 12 }}
          justifyContent="center"
          style={{
            display: 'flex',
            height: `calc(100% - ${headerHeight}px)`,
          }}
        >
          {!categoryKey && <PricingClientUpdateCategories searchValue={searchValue} setSearchValue={setSearchValue} />}
          {categoryKey && pricingTab === PricingTab.Component && (
            // Table view for component categories
            <PricingComponentCategoryItems searchValue={searchValue} />
          )}
          {categoryKey && pricingTab === PricingTab.SizeBased && (
            // Pricing sheet view for a size based category
            <PricingSizeBasedPricingSheets />
          )}
        </Grid>
      </Grid>

      <PricingComponentEditDialog />
      <PricingClientUpdatePreviewDialog />
      <PricingPublishDialog
        pricingBranch={
          pricingTab === PricingTab.Component ? ClientDataBranch.ClientUpdate : ClientDataBranch.PricingSizeBased
        }
        title={
          pricingTab === PricingTab.Component
            ? I18nKeys.PricingComponentPublishDialogTitle
            : I18nKeys.PricingBasePublishDialogTitle
        }
        content={I18nKeys.PricingComponentPublishDialogText}
      />
      <PricingPublishResultDialog />
    </div>
  );
};
