import React from 'react';
import { Card, CardContent, Grid2 as Grid, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#FFFFFF',
    flex: 1,
  },
  stats: {
    width: '95%',
    margin: '20px auto 0 auto',
  },
  statsGrid: {
    padding: '16px 0px 10px',
  },
  statsCardContent: {
    padding: '8px',
  },
});

interface Props {
  stats: { i18nKey: string; value: string | number }[];
}

export const StatsDashboard: React.FC<Props> = ({ stats }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.stats} elevation={3}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {stats.map((stat) => (
            <Grid size={{ xs: 12, sm: 6, md: 3 }} className={classes.statsGrid} key={stat.i18nKey}>
              <Card>
                <CardContent className={classes.statsCardContent}>
                  <Typography variant="h5" component="h2" textAlign="center" color="text.secondary">
                    {stat.value}
                  </Typography>
                  <Typography variant="h6" component="h3" textAlign="center" color="text.secondary">
                    {t(stat.i18nKey)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </div>
  );
};
