import { Divider } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import { navigate } from 'hookrouter';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from '../constants/AppRoutes';
import { setSelectedClientId, resetClientIds } from '../ducks/viewerSlice';
import { fetchGroups } from '../ducks/groups';
import { GroupData } from '../types/Group';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { SearchInput } from './SearchInput';
import { i18n } from '../i18n';
import { I18nKeys } from '../constants/I18nKeys';
import { Language } from '../constants/Languages';
import { onTableMenuKeyboardShortcut } from '../utils/clientDataKeyboardShortcutHandlerUtils';
import { AnchorMenu } from './AnchorMenu';
import { fuzzyMatchIncludes } from '../utils/stringUtils';
import { changeGroup, signOut as signOutFunc } from '../ducks/currentUserSlice';
import { useAppSelector, useAppDispatch } from '../hooks';
import { unknownUser } from '../types/User';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  button: { textTransform: 'none', width: '100%', display: 'flex', color: theme.palette.text.primary },
  buttonText: { flex: 1, marginLeft: '8px' },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: '42px',
    height: '42px',
  },
  user: {
    padding: '5px 16px',
  },
  groupsList: {
    maxHeight: '500px',
    overflow: 'overlay',
  },
  search: {
    padding: '16px',
  },
}));

const emptyGroupList: GroupData[] = [];

export const UserMenuItem: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const searchRef = React.useRef<HTMLDivElement>(null);
  const [matchingGroups, setMatchingGroups] = React.useState<GroupData[]>([]);
  const [matchingGroupOptions, setMatchingGroupOptions] = React.useState<{ key: string; value: string }[]>([]);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const resetSearch = React.useCallback(() => setSearchValue(''), []);
  const [currentLanguage, setCurrentLanguage] = React.useState<Language | undefined>(undefined);

  const [checkedIndex, setCheckedIndex] = React.useState<number>(0);
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);

  const { email = '', name = '' } = useAppSelector((state) => state.currentUser.user) || {};
  const { groupId = '', groupName = '' } = useAppSelector((state) => state.currentUser.group) || {};
  const groups = useAppSelector((state) => state.currentUser.groups) || emptyGroupList;
  const { defaultClientId = '', languages = [] } = useAppSelector((state) => state.viewer);
  const userInitials = useAppSelector(
    ({ currentUser: { user: { firstName = '', lastName = '' } = unknownUser } }) =>
      `${firstName.charAt(0)}${lastName.charAt(0)}`,
  );

  useEffect(() => {
    if (groupId) {
      setCheckedIndex(matchingGroupOptions.findIndex((group) => group.key === groupId));
    }
  }, [groupId, groups, matchingGroups]);

  useEffect(() => {
    let displayedGroups;
    if (searchValue.length < 2) {
      displayedGroups = groups;
    } else {
      displayedGroups = groups.filter((group) => {
        const { groupName: filterName, groupId: filterId, configurators = [] } = group;
        return [filterName, filterId, ...configurators.map(({ vendor = '' }) => vendor)].some(
          (v) => v && fuzzyMatchIncludes(v, searchValue),
        );
      });
    }

    setMatchingGroups(displayedGroups);
    setMatchingGroupOptions(
      displayedGroups.map((group) => ({ key: group.groupId || '', value: group.groupName || '' })),
    );
    setSelectedIndex(0);
  }, [searchValue, groups, setMatchingGroups]);

  useEffect(() => {
    setCurrentLanguage(languages.find((lng: Language) => lng.key === i18n.language));
  }, [languages]);

  const getChildNodeById = (parentEl: HTMLElement | null, id: string): HTMLElement | undefined =>
    Array.from(parentEl?.children || []).find((node) => node?.id === id) as HTMLElement | undefined;

  useEffect(() => {
    if (anchorEl) searchRef.current?.focus();
  }, [anchorEl]);

  const menuListRef = React.useRef<HTMLUListElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
    resetSearch();
  };

  const focusMenuItem = (index: number) => {
    setSelectedIndex(index);
  };

  const clickMenuItem = () => {
    const id = matchingGroups[selectedIndex]?.groupId;
    if (id) {
      const child = getChildNodeById(menuListRef?.current as HTMLUListElement, id);
      child?.click();
    }
  };

  const handleGroupMenuItemClick = (selectedGroupId: string): void => {
    handleClose();
    dispatch(resetClientIds());
    dispatch(changeGroup(selectedGroupId));
    dispatch(fetchGroups());
  };

  const handleProfileClicked = (): void => {
    dispatch(setSelectedClientId(defaultClientId));
    navigate(AppRoutes.Profile);
  };

  const handleSignOutClicked = (): void => {
    dispatch(resetClientIds());
    dispatch(signOutFunc());
  };

  return (
    <div className={classes.root}>
      <Button
        id="userMenu"
        className={classes.button}
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        <Avatar className={classes.avatar}>{userInitials}</Avatar>
        <Typography color="textPrimary" component="div" className={classes.buttonText}>
          <Box textAlign="left" fontWeight="fontWeightBold" fontSize="h6.fontSize" lineHeight={1.2}>
            {name || email}
          </Box>
          <Box textAlign="left" fontWeight="fontWeightLight">
            {groupName}
          </Box>
        </Typography>
      </Button>
      <AnchorMenu
        menuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
        }}
        maxMenuHeight="500px"
        menuAnchorEl={anchorEl}
        listRef={menuListRef}
        onClose={handleClose}
        options={matchingGroupOptions}
        checkedOptions={[matchingGroupOptions[checkedIndex]?.key]}
        selectedOptions={[matchingGroupOptions[selectedIndex]?.key]}
        checkedIcon={<CheckIcon />}
        onClick={(id): void => handleGroupMenuItemClick(id)}
        onKeyDown={(i: number) => (e: React.KeyboardEvent<HTMLDivElement | HTMLLIElement>) =>
          onTableMenuKeyboardShortcut(e, { currentIndex: i, focusMenuItem, clickMenuItem })}
        searchInput={
          groups &&
          groups.length > 10 && (
            <MenuItem
              key="group-search"
              className={classes.search}
              onKeyDown={(e): void => {
                if (e.key !== 'Escape') {
                  e.stopPropagation();
                }
              }}
            >
              <SearchInput
                ref={searchRef}
                autoComplete="off"
                searchTerm={searchValue}
                startAdornment={<SearchIcon />}
                onClearClick={resetSearch}
                onChange={setSearchValue}
                size="small"
                onKeyDown={(e) =>
                  onTableMenuKeyboardShortcut(e, { currentIndex: selectedIndex, focusMenuItem, clickMenuItem })
                }
              />
            </MenuItem>
          )
        }
        pinnedBottomOptions={[
          ...(groups && groups.length > 1 ? [<Divider key="menu-divider-1" />] : []),
          <MenuItem key={I18nKeys.UserMenuItemMyProfile} onClick={handleProfileClicked}>
            <ListItemText primary={t(I18nKeys.UserMenuItemMyProfile)} />
          </MenuItem>,
          ...(languages.filter((lng: Language) => !lng.hidden).length > 1
            ? [
                <MenuItem key="select-language" onClick={() => dispatch(openDialog({ dialog: Dialogs.Language }))}>
                  <ListItemText
                    primary={(currentLanguage && currentLanguage.label) || t(I18nKeys.UserMenuItemLanguage)}
                  />
                </MenuItem>,
              ]
            : []),
          <Divider key="menu-divider-2" />,
          <MenuItem key={I18nKeys.UserMenuItemLogOut}>
            <ListItemText onClick={handleSignOutClicked} primary={t(I18nKeys.UserMenuItemLogOut)} />
          </MenuItem>,
        ]}
      />
    </div>
  );
};
