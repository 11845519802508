import { Box, CircularProgress, Grid2 as Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PricingSurchargeCalculationType, PricingSurchargeSupplierOption } from '@idearoom/types';
import { AppState } from '../types/AppState';
import { unknownGroup } from '../constants/Group';
import { ConfiguratorEnabledOnProps } from '../types/Configurator';
import { setSearchHidden } from '../ducks/search';
import { PricingSurchargeSupplierSelector } from './PricingSurchargeSupplierSelector';
import { getEnabledOnProperty } from '../utils/vendorDataUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  getClientIdFromClientSupplier,
  getSupplierKeyFromClientSupplier,
  getVendorFromClientId,
  mapConfiguratorToClientId,
} from '../utils/clientIdUtils';
import { hasSuppliers, viewingDealer } from '../utils/supplierUtils';
import { Supplier } from '../types/Supplier';
import { ClientDataType } from '../constants/ClientDataType';
import { setClientDataType, setSelectedTable } from '../ducks/clientDataSlice';
import { setSurchargeSupplierKey } from '../ducks/pricingSlice';
import { PRICING_SURCHARGE_TABLE } from '../constants/ClientData';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { PricingSurchargeCalculationTypeSelector } from './PricingSurchargeCalculationTypeSelector';
import { PricingSurchargeAmount } from './PricingSurchargeAmount';
import { PricingSurchargeCalculation } from './PricingSurchargeCalculation';
import { PricingPublishDialog } from './PricingPublishDialog';
import { PricingPublishResultDialog } from './PricingPublishResultDialog';
import { PricingSurchargePreviewDialog } from './PricingSurchargePreviewDialog';
import { usePricingRepo } from '../hooks/usePricingRepo';

const useStyles = makeStyles({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  rootGrid: {
    padding: '2%',
    flex: 1,
  },
  spacing: {
    width: '100%',
    marginTop: '25px',
  },
  exportButton: {
    marginRight: '25%',
  },
  exportText: {
    fontSize: '14px',
    fontWeight: 600,
    marginLeft: '6px',
  },
});

export const PricingSurcharge: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { clientId, clientDataType, selectedTable, isCreatingBranch } = useAppSelector((state) => state.clientData);
  const supplierKey = useAppSelector((state) => state.pricing.surcharge.supplierKey || '');

  const { isInitializingSelectedPricingSurcharge, selectedPricingSurcharge } = usePricingRepo({
    useSelectedPricingSurcharge: true,
  });
  const { key: pricingSelection, supplierSurcharge: pricingSupplierSelection } = selectedPricingSurcharge;

  if (clientDataType !== ClientDataType.Vendor) {
    dispatch(setClientDataType(ClientDataType.Vendor));
  }

  if (selectedTable !== PRICING_SURCHARGE_TABLE) {
    dispatch(setSelectedTable(PRICING_SURCHARGE_TABLE));
  }

  const selectedViewerId = useAppSelector(
    (state: AppState) => state?.viewer?.selectedTabId || state?.viewer?.selectedClientId,
  );

  const { configurators: groupConfigs = [] } = useAppSelector((state) => state.currentUser.group || unknownGroup);

  const selectedConfigurator = groupConfigs.find((c) => c.clientId === selectedViewerId) || groupConfigs[0];
  const isDealer = viewingDealer(selectedViewerId, groupConfigs);
  const pricingSupplierSelectionOption =
    pricingSupplierSelection ||
    (isDealer ? PricingSurchargeSupplierOption.Inherit : PricingSurchargeSupplierOption.Off);
  const suppliersExist = hasSuppliers(selectedConfigurator);
  const filteredConfigurators = (groupConfigs || []).filter((c) =>
    getEnabledOnProperty(c.vendorData, ConfiguratorEnabledOnProps.PricingEnabled, true),
  );

  const setSupplierForPricing = (supplier: string) => dispatch(setSurchargeSupplierKey(supplier));

  useEffect(() => {
    if (isInitializingSelectedPricingSurcharge || isCreatingBranch) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isInitializingSelectedPricingSurcharge, isCreatingBranch]);

  useEffect(() => {
    // No client ID is selected or the client ID is invalid
    if ((!clientId || !selectedConfigurator) && filteredConfigurators.length > 0) {
      const initialClientId = getClientIdFromClientSupplier(selectedViewerId);
      const initialSupplierKey = getSupplierKeyFromClientSupplier(selectedViewerId);

      if (
        filteredConfigurators.some(
          (c) =>
            mapConfiguratorToClientId(c) === initialClientId &&
            (!initialSupplierKey ||
              getVendorFromClientId(initialClientId) === initialSupplierKey ||
              c.suppliers?.some((s) => s.supplierKey === initialSupplierKey)),
        )
      ) {
        // Initial viewer ID is valid
        setSupplierForPricing(initialSupplierKey);
      } else {
        // Use the first configurator and supplier (if it exists)
        setSupplierForPricing(
          hasSuppliers(filteredConfigurators[0])
            ? (filteredConfigurators[0]?.suppliers || [])[0]?.supplierKey || ''
            : '',
        );
      }
    }
    if (clientId && selectedConfigurator && suppliersExist && !supplierKey) {
      setSupplierForPricing((selectedConfigurator.suppliers as Supplier[])[0].supplierKey);
    }
  }, [clientId, supplierKey, selectedConfigurator, suppliersExist]);

  // Effect that removes the search before the browser paints
  useLayoutEffect(() => {
    dispatch(setSearchHidden(true));
  }, [dispatch]);

  return (
    <div className={classes.root}>
      {!isLoading && (
        <Grid
          className={classes.rootGrid}
          container
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <>
            {isDealer && (
              <Grid>
                <Box>
                  <PricingSurchargeSupplierSelector />
                </Box>
              </Grid>
            )}
            <Grid>
              <Typography variant="h6" component="h6">
                {isDealer ? t(I18nKeys.PricingChangesSupplierPrefix) : ''}
                {t(I18nKeys.PricingChangesTitle)}
              </Typography>
              <Box ml={4}>
                <PricingSurchargeCalculationTypeSelector
                  dealer={isDealer}
                  disabled={pricingSupplierSelectionOption === PricingSurchargeSupplierOption.Inherit}
                />
              </Box>
            </Grid>
            {pricingSelection &&
              pricingSelection !== PricingSurchargeCalculationType.None &&
              pricingSupplierSelectionOption !== PricingSurchargeSupplierOption.Inherit && (
                <>
                  <Grid className={classes.spacing}>
                    <Typography variant="h6" component="h6">
                      {t(I18nKeys.PricingChangesCalculation)}
                    </Typography>
                    <Box ml={4}>
                      <PricingSurchargeCalculation />
                    </Box>
                  </Grid>
                  <Grid className={classes.spacing}>
                    <Typography variant="h6" component="h6">
                      {t(I18nKeys.PricingChangesAmounts)}
                    </Typography>
                    <Box ml={4}>
                      <PricingSurchargeAmount />
                    </Box>
                  </Grid>
                </>
              )}
          </>
        </Grid>
      )}
      {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
          <CircularProgress style={{ alignSelf: 'center' }} color="primary" />
        </div>
      )}

      <PricingSurchargePreviewDialog />
      <PricingPublishDialog
        pricingBranch={ClientDataBranch.PricingSurcharge}
        title={I18nKeys.PricingSurchargePublishDialogTitle}
        content={I18nKeys.PricingSurchargePublishDialogText}
      />
      <PricingPublishResultDialog />
    </div>
  );
};
