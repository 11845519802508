import { toast } from 'react-toastify';
import { reset, SubmissionError } from 'redux-form';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { BulkUserDialogFormFields, UserDialogFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { closeDialog, openDialog } from '../ducks/dialogSlice';
import {
  addMember,
  addMemberError,
  addMemberSuccess,
  editMember,
  editMemberError,
  editMemberSuccess,
  fetchGroupError,
  fetchGroupSuccess,
  GroupActionTypes,
  removeConfiguratorFromGroupError,
  removeConfiguratorFromGroupSuccess,
  removeMemberError,
  removeMemberSuccess,
  sendNewMemberInvitationSuccess,
  sendNewMemberInvitationError,
  resendMemberInvitationError,
  resendMemberInvitationSuccess,
  resetMemberPasswordError,
  resetMemberPasswordSuccess,
  toggleEnabledOnPropertySuccess,
  addBulkMemberSuccess,
  addBulkMemberError,
  addBulkMember,
  updateGroupSettingsSuccess,
} from '../ducks/group';
import {
  fetchGroupsError,
  fetchGroupsSuccess,
  GroupsActionTypes,
  removeGroupError,
  removeGroupSuccess,
} from '../ducks/groups';
import { AppState } from '../types/AppState';
import { Configurator } from '../types/Configurator';
import { Group } from '../types/Group';
import { defaultGroupSettings, unknownGroup } from '../constants/Group';
import { BulkMemberResult, GroupMember } from '../types/GroupMember';
import { GroupState } from '../types/GroupState';
import { isIdeaRoomGroup } from '../utils/userUtils';
import { formatPhoneNumber } from '../utils/phoneNumberUtils';
import { Dialogs } from '../constants/Dialogs';
import { User } from '../types/User';
import { openNotificationDialog } from '../ducks/notificationDialog';
import { getEnabledOnProperty } from '../utils/vendorDataUtils';
import { extractErrorProps } from '../utils/errorUtils';
import { defaultErrorMessage } from '../constants/Error';
import { userApi } from '../services/userApi';
import { clientDataApi } from '../services/clientDataApi';
import { mapConfiguratorToClientId } from '../utils/clientIdUtils';
import { Vendor } from '../types/VendorData';
import { groupApi } from '../services/groupApi';
import { salesRepApi } from '../services/salesRepApi';
import { vendorDataApi } from '../services/vendorDataApi';
import { clearWarningText } from '../ducks/viewerSlice';

function* fetchGroup({ payload: { groupId } }: any): Generator {
  try {
    const getGroupFetch = (yield put(
      groupApi.endpoints.getGroup.initiate({
        groupId,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    getGroupFetch.unsubscribe();

    const {
      data: { group = unknownGroup },
    } = (yield getGroupFetch) as { data: { group: Group } };

    let { configurators, members, settings } = group;
    if (!configurators) configurators = [];
    if (!members) members = [];
    if (!settings) settings = defaultGroupSettings;

    const configs = [...configurators];
    for (let index = 0; index < configs.length; index += 1) {
      const configurator = configs[index];
      let name = '';
      let supplierKey = '';
      try {
        const clientId = mapConfiguratorToClientId(configurator);
        const getVendorDataFetch = (yield put(
          clientDataApi.endpoints.getVendorData.initiate({
            clientId,
          }) as any,
        )) as QueryActionCreatorResult<any>;
        getVendorDataFetch.unsubscribe();

        ({ data: { name = '', supplierKey = '' } = {} } = (yield getVendorDataFetch) as { data: Vendor });
      } catch (e) {
        console.error(`Failed to retrieve ${configurator.key} vendor ${configurator.vendor}`);
        continue; // eslint-disable-line no-continue
      }

      configs[index] = {
        ...configurator,
        name,
        supplierKey,
      };
    }
    yield put(
      fetchGroupSuccess({
        ...group,
        updating: false,
        configurators: configs.map((configurator: Configurator) => ({ ...configurator, updating: false })),
        members: members.map((member: GroupMember) => ({
          ...member,
          updating: false,
        })),
        settings,
      }),
    );
  } catch (error) {
    yield put(fetchGroupError());
  }
}

function* fetchGroups(): Generator {
  try {
    const getUserGroupsFetch = (yield put(
      groupApi.endpoints.getUserGroups.initiate() as any,
    )) as QueryActionCreatorResult<any>;
    getUserGroupsFetch.unsubscribe();

    const { data: { groups = [] } = {} } = (yield getUserGroupsFetch) as { data: { groups: Group[] } };

    const sortedGroups = [...groups].sort((firstGroup: Group, secondGroup: Group) => {
      if (isIdeaRoomGroup(firstGroup.groupId)) {
        return -1;
      }

      if (isIdeaRoomGroup(secondGroup.groupId)) {
        return 1;
      }

      return 0;
    });

    yield put(fetchGroupsSuccess(sortedGroups.map((group: Group) => ({ ...group, updating: false }))));
  } catch (error) {
    yield put(fetchGroupsError());
  }
}

function* removeGroup({ payload: { group } }: any): Generator {
  try {
    const { groupId, groupName } = group;

    const deleteGroupsFetch = (yield put(
      groupApi.endpoints.deleteGroup.initiate({
        groupId,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    deleteGroupsFetch.unsubscribe();

    yield deleteGroupsFetch;

    yield put(removeGroupSuccess(group));

    yield call(toast.success, `Group "${groupName}" successfully deleted.`);
  } catch (error) {
    yield put(removeGroupError(group));

    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

function* toggleEnabledOnProperty({ payload: { configurator, enabledOnProperty } }: any): Generator {
  try {
    const vendorData = {
      ...configurator.vendorData,
      vendor: {
        ...(configurator.vendorData || {}).vendor,
        [enabledOnProperty]: !getEnabledOnProperty(configurator.vendorData, enabledOnProperty, true),
      },
    };
    const newConfigurator = { ...configurator, vendorData };

    const updateVendorDataFetch = (yield put(
      vendorDataApi.endpoints.updateVendorData.initiate({
        clientId: configurator.clientId || `${configurator.key}-${configurator.vendor}`,
        vendorData,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    updateVendorDataFetch.unsubscribe();

    yield updateVendorDataFetch;

    yield put(toggleEnabledOnPropertySuccess(newConfigurator, enabledOnProperty));
  } catch (error) {
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

function* removeConfiguratorFromGroup({ payload: { configurator } }: any): Generator {
  try {
    const { groupId, members } = (yield select(({ group: { group } }: AppState) => group)) as Group;

    const { key, vendor } = configurator;

    const removeConfiguratorFetch = (yield put(
      groupApi.endpoints.removeConfiguratorFromGroup.initiate({
        groupId,
        configurator: { key, vendor, updating: false },
      }) as any,
    )) as QueryActionCreatorResult<any>;
    removeConfiguratorFetch.unsubscribe();

    yield removeConfiguratorFetch;

    const salesReps = members || [];
    const emails = salesReps.map((salesRep) => salesRep.email);
    const removeSalesRepFetch = (yield put(
      salesRepApi.endpoints.removeSalesRepsFromClient.initiate({
        groupId,
        clientId: `${key}-${vendor}`,
        emails,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    removeSalesRepFetch.unsubscribe();

    yield removeSalesRepFetch;

    yield put(removeConfiguratorFromGroupSuccess(configurator));

    yield call(toast.success, `Group configurators successfully updated.`);
  } catch (error) {
    yield put(removeConfiguratorFromGroupError(configurator));

    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

function* saveMember({
  reject,
  resolve,
  values: {
    [UserDialogFormFields.Email]: email,
    [UserDialogFormFields.FirstName]: firstName,
    [UserDialogFormFields.LastName]: lastName,
    [UserDialogFormFields.PhoneNumber]: phone,
    [UserDialogFormFields.Permissions]: permissions,
    [UserDialogFormFields.Dealers]: dealers,
  },
}: any): Generator {
  const { groupId, configurators = [] } = (yield select(({ group: { group } }: AppState) => group)) as Group;
  const { newMember } = (yield select(({ group }: AppState) => group)) as GroupState;
  const formattedPhoneNumber = formatPhoneNumber(phone);
  const toastUsername = `${firstName ? `${firstName} ${lastName}` : email}`;
  const memberDealers = Array.isArray(dealers) ? dealers : [dealers];
  try {
    if (newMember) {
      yield put(addMember());

      const addToGroupFetch = (yield put(
        userApi.endpoints.addUserToGroup.initiate({
          groupId,
          email,
          firstName,
          lastName,
          phone: formattedPhoneNumber,
          permissions,
          dealers: memberDealers,
        }) as any,
      )) as QueryActionCreatorResult<any>;
      addToGroupFetch.unsubscribe();

      const {
        data: { member },
      } = (yield addToGroupFetch) as { data: { member: GroupMember } };

      // Add user to the Sales Rep database to allow them access to Sales Tools
      // This will be deleted when we implement the Sales Tools login
      const clientIds = configurators.map((config) => config.clientId || `${config.key}-${config.vendor}`);
      const addSalesRepsFetch = (yield put(
        salesRepApi.endpoints.addSalesRepsToClients.initiate({
          groupId,
          salesReps: [{ email }],
          clientIds,
        }) as any,
      )) as QueryActionCreatorResult<any>;
      addSalesRepsFetch.unsubscribe();
      yield addSalesRepsFetch;

      yield call(resolve);

      yield put(reset(Forms.User));

      yield put(closeDialog());

      yield put(addMemberSuccess(member));

      yield put(clearWarningText());

      yield call(toast.success, `${toastUsername} is now a member of the group.`);
    } else {
      const { member } = (yield select(({ group }: AppState) => group)) as GroupState;
      yield put(editMember(member));

      const updateUserFetch = (yield put(
        userApi.endpoints.updateUserInGroup.initiate({
          groupId,
          email,
          firstName,
          lastName,
          phone: formattedPhoneNumber,
          permissions,
          dealers: memberDealers,
        }) as any,
      )) as QueryActionCreatorResult<any>;
      updateUserFetch.unsubscribe();

      const {
        data: { member: editedMember },
      } = (yield updateUserFetch) as { data: { member: GroupMember } };

      // Add user to the Sales Rep database to allow them access to Sales Tools
      // This will be deleted when we implement the Sales Tools login
      const clientIds = configurators.map((config) => config.clientId || `${config.key}-${config.vendor}`);
      const addSalesRepsFetch = (yield put(
        salesRepApi.endpoints.addSalesRepsToClients.initiate({
          groupId,
          salesReps: [{ email }],
          clientIds,
        }) as any,
      )) as QueryActionCreatorResult<any>;
      addSalesRepsFetch.unsubscribe();
      yield addSalesRepsFetch;

      yield call(resolve);

      yield put(reset(Forms.User));

      yield put(closeDialog());

      yield put(editMemberSuccess({ ...editedMember, permissions, dealers: memberDealers }));

      yield call(toast.success, `${toastUsername} has been successfully updated.`);
    }
  } catch (error) {
    yield put(newMember ? addMemberError() : editMemberError());

    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(reject, new SubmissionError({ _error: errorMessage }));
  }
}

function* saveBulkMember({
  reject,
  resolve,
  values: {
    [BulkUserDialogFormFields.Emails]: emails = [],
    [BulkUserDialogFormFields.Permissions]: permissions,
    [BulkUserDialogFormFields.Dealers]: dealers,
  },
}: any): Generator {
  const { groupId, configurators = [] } = (yield select(({ group: { group } }: AppState) => group)) as Group;
  try {
    yield put(addBulkMember());

    const bulkAddFetch = (yield put(
      userApi.endpoints.addBulkMembersToGroup.initiate({
        groupId,
        emails,
        permissions,
        dealers,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    bulkAddFetch.unsubscribe();

    const {
      data: { bulkMemberResult },
    } = (yield bulkAddFetch) as { data: { bulkMemberResult: BulkMemberResult } };

    // Add user to the Sales Rep database to allow them access to Sales Tools
    // This will be deleted when we implement the Sales Tools login
    const clientIds = configurators.map((config) => config.clientId || `${config.key}-${config.vendor}`);
    const addSalesRepsFetch = (yield put(
      salesRepApi.endpoints.addSalesRepsToClients.initiate({
        groupId,
        salesReps: emails.map((email: string) => ({ email })),
        clientIds,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    addSalesRepsFetch.unsubscribe();
    yield addSalesRepsFetch;

    yield call(resolve);

    yield put(reset(Forms.BulkUser));

    yield put(closeDialog());

    yield put(addBulkMemberSuccess(bulkMemberResult));

    const notificationTitle = 'Bulk Member Import Results';

    const notificationContent = `Successfully added ${bulkMemberResult.successfulUsers.length} user(s):\n
    ${bulkMemberResult.successfulUsers.map((user: User) => ` * ${user.username}`).join('\n')}\n
    Failed to add ${bulkMemberResult.errors.length} user(s):\n
    ${bulkMemberResult.errors
      .map((error: { username: string; error: string }) => ` * ${error.username}: ${error.error}`)
      .join('\n')}`;

    yield put(openNotificationDialog(notificationTitle, notificationContent));
    yield put(openDialog({ dialog: Dialogs.Notification }));
  } catch (error) {
    yield put(addBulkMemberError());

    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(reject, new SubmissionError({ _error: errorMessage }));
  }
}

function* sendNewMemberInvitation({ payload: { member } }: any): Generator {
  try {
    const { groupId } = (yield select(({ group: { group } }: AppState) => group)) as Group;
    const { username } = member;

    const sendInvitationFetch = (yield put(
      userApi.endpoints.sendUserInvitation.initiate({
        username,
        groupId,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    sendInvitationFetch.unsubscribe();
    yield sendInvitationFetch;

    yield put(sendNewMemberInvitationSuccess(member));

    const { email } = member;
    yield call(toast.success, `Invitation sent to ${email}.`);
  } catch (error) {
    yield put(sendNewMemberInvitationError(member));

    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

function* resendMemberInvitation({ payload: { member } }: any): Generator {
  try {
    const { groupId } = (yield select(({ group: { group } }: AppState) => group)) as Group;
    const { username } = member;

    const resendInvitationFetch = (yield put(
      userApi.endpoints.resendUserInvitation.initiate({
        username,
        groupId,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    resendInvitationFetch.unsubscribe();
    yield resendInvitationFetch;

    yield put(resendMemberInvitationSuccess(member));

    const { email } = member;
    yield call(toast.success, `Invitation sent to ${email}.`);
  } catch (error) {
    yield put(resendMemberInvitationError(member));

    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

function* resetMemberPassword({ payload: { member } }: any): Generator {
  try {
    const { groupId } = (yield select(({ group: { group } }: AppState) => group)) as Group;
    const { username } = member;

    const resetPasswordFetch = (yield put(
      userApi.endpoints.resetUserPassword.initiate({
        username,
        groupId,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    resetPasswordFetch.unsubscribe();
    yield resetPasswordFetch;

    yield put(resetMemberPasswordSuccess(member));

    const { email } = member;
    yield call(toast.success, `Password reset successfully requested for the user ${email}.`);
  } catch (error) {
    yield put(resetMemberPasswordError(member));

    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

function* removeMemberFromGroup({ payload: { member } }: any): Generator {
  try {
    const { groupId, configurators = [] } = (yield select(({ group: { group } }: AppState) => group)) as Group;
    const { email } = member;

    const deleteUserFetch = (yield put(
      userApi.endpoints.deleteUser.initiate({
        groupId,
        username: email,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    deleteUserFetch.unsubscribe();
    yield deleteUserFetch;

    // Remove user from the Sales Rep database to deny them access to Sales Tools
    // This will be deleted when we implement the Sales Tools login
    const clientIds = configurators.map((config) => config.clientId || `${config.key}-${config.vendor}`);
    const deleteSalesRepFetch = (yield put(
      salesRepApi.endpoints.removeSalesRep.initiate({
        groupId,
        email,
        clientIds,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    deleteSalesRepFetch.unsubscribe();
    yield deleteSalesRepFetch;

    yield put(removeMemberSuccess(member));

    yield call(toast.success, `${member.email} is no longer a member of the group.`);
  } catch (error) {
    yield put(removeMemberError(member));

    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

function* updateGroupSettings({ payload: { settings } }: any): Generator {
  try {
    const { groupId } = (yield select(({ group: { group } }: AppState) => group)) as Group;

    const updateSettingsFetch = (yield put(
      groupApi.endpoints.updateGroupSettings.initiate({
        groupId,
        settings,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    updateSettingsFetch.unsubscribe();
    yield updateSettingsFetch;

    yield put(updateGroupSettingsSuccess(settings));
  } catch (error) {
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

function* watchRemoveGroup(): Generator {
  yield takeLatest(GroupsActionTypes.REMOVE_GROUP, removeGroup);
}

function* watchFetchGroup(): Generator {
  yield takeLatest(GroupActionTypes.FETCH_GROUP, fetchGroup);
}

function* watchFetchGroups(): Generator {
  yield takeLatest(GroupsActionTypes.FETCH_GROUPS, fetchGroups);
}

function* watchRemoveConfiguratorFromGroup(): Generator {
  yield takeLatest(GroupActionTypes.REMOVE_CONFIGURATOR_FROM_GROUP, removeConfiguratorFromGroup);
}

function* watchToggleEnabledOnProperty(): Generator {
  yield takeLatest(GroupActionTypes.TOGGLE_ENABLED_ON_PROPERTY, toggleEnabledOnProperty);
}

function* watchSubmitUserForm(): Generator {
  yield takeLatest(`${Forms.User}_SUBMIT`, saveMember);
}

function* watchSubmitBulkUserForm(): Generator {
  yield takeLatest(`${Forms.BulkUser}_SUBMIT`, saveBulkMember);
}

function* watchRemoveUserFromGroup(): Generator {
  yield takeLatest(GroupActionTypes.REMOVE_MEMBER, removeMemberFromGroup);
}

function* watchSendNewMemberInvitation(): Generator {
  yield takeLatest(GroupActionTypes.SEND_NEW_MEMBER_INVITATION, sendNewMemberInvitation);
}

function* watchResendMemberInvitation(): Generator {
  yield takeLatest(GroupActionTypes.RESEND_MEMBER_INVITATION, resendMemberInvitation);
}

function* watchResetMemberPassword(): Generator {
  yield takeLatest(GroupActionTypes.RESET_MEMBER_PASSWORD, resetMemberPassword);
}

function* watchUpdateGroupSettings(): Generator {
  yield takeLatest(GroupActionTypes.UPDATE_GROUP_SETTINGS, updateGroupSettings);
}

export function* groupsSaga(): Generator {
  yield all([
    watchFetchGroup(),
    watchRemoveGroup(),
    watchFetchGroups(),
    watchRemoveConfiguratorFromGroup(),
    watchSubmitUserForm(),
    watchSubmitBulkUserForm(),
    watchRemoveUserFromGroup(),
    watchSendNewMemberInvitation(),
    watchResendMemberInvitation(),
    watchResetMemberPassword(),
    watchToggleEnabledOnProperty(),
    watchUpdateGroupSettings(),
  ]);
}
