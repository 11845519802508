import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Icon, Button, Popover, Stack, alpha } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../hooks';
import { I18nKeys } from '../constants/I18nKeys';
import { GridViewType } from '../constants/GridViewType';
import { UserPreference } from '../constants/User';
import { unknownGroup } from '../constants/Group';
import { AppState } from '../types/AppState';
import checkIcon from '../images/checkIcon.svg';
import selectGridIcon from '../images/selectGridIcon.svg';
import { saveUserPreferences } from '../ducks/currentUserSlice';
import { PricingTab } from '../constants/Pricing';

const useStyles = makeStyles(() => ({
  selectViewButton: {
    height: 'fit-content',
    borderRadius: '4px',
    justifyContent: 'flex-start',
    color: alpha('#000', 0.87),
    background: alpha('#000', 0.08),
    padding: '8px 18px',
  },
  selectViewIcon: {
    width: '24px',
    height: '24px',
  },
  selectViewText: {
    fontWeight: '500',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    lineHeight: '16px',
  },
  viewOptionText: {
    fontWeight: '400',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    lineHeight: '20px',
    textTransform: 'capitalize',
  },
  viewOptionIcon: {
    width: '24px',
    height: '24px',
    paddingRight: '30px',
  },
  viewOptionButton: {
    border: 'border: 1px solid #F4F4F4',
    height: 'fit-content',
    color: '#424242',
    justifyContent: 'flex-start',
    background: '#FFFFFF',
    padding: '8px 48px',
  },
}));

type Props = {
  gridViewType: GridViewType;
};

export const PricingSheetViewSelect: React.FC<Props> = ({ gridViewType }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const viewerClientId = useAppSelector(
    (state: AppState) => state?.viewer?.selectedTabId || state?.viewer?.selectedClientId || '',
  );
  const { selectedPricingTabId: pricingTab } = useAppSelector((state) => state.viewer);
  const {
    group: { groupId } = unknownGroup,
    preferences: { [UserPreference.PricingBasePreferences]: pricingBasePreferences = { gridViewType: {} } } = {},
  } = useAppSelector((state) => state.currentUser);

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const getOptionText = (viewType: GridViewType) =>
    t(viewType === GridViewType.Grid ? I18nKeys.PricingBaseViewTypeGrid : I18nKeys.PricingBaseViewTypeList);

  const handleOpenSelectView = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }

    setOpen(!open);
  };

  const handleCloseSelectView = () => {
    setOpen(false);
  };

  const handleSelectViewOption = (option: string) => {
    if (gridViewType !== option) {
      const prefs = {
        ...pricingBasePreferences,
        gridViewType: {
          ...pricingBasePreferences.gridViewType,
          [groupId]: { ...(pricingBasePreferences.gridViewType[groupId] || {}), [viewerClientId]: option },
        },
      };
      dispatch(saveUserPreferences({ userPreference: UserPreference.PricingBasePreferences, preferences: prefs }));
    }

    setOpen(false);
  };

  if (pricingTab === PricingTab.SizeBased) return null;
  return (
    <>
      <Button
        className={classes.selectViewButton}
        startIcon={
          <Icon className={classes.selectViewIcon}>
            <img alt="select grid view type" src={selectGridIcon} />
          </Icon>
        }
        onClick={handleOpenSelectView}
      >
        <Typography className={classes.selectViewText}>{t(I18nKeys.PricingBaseSelectViewButtonTitle)}</Typography>
      </Button>
      <Popover
        open={open}
        onClose={handleCloseSelectView}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Stack spacing={0}>
          {Object.values(GridViewType).map((option) => (
            <Button
              key={option}
              onClick={() => {
                handleSelectViewOption(option);
              }}
              className={classes.viewOptionButton}
              startIcon={
                <Icon className={classes.viewOptionIcon}>
                  {gridViewType === option ? <img alt="checked" src={checkIcon} /> : undefined}
                </Icon>
              }
            >
              <Typography className={classes.viewOptionText}>{getOptionText(option)}</Typography>
            </Button>
          ))}
        </Stack>
      </Popover>
    </>
  );
};
