import { createApi } from '@reduxjs/toolkit/query/react';
import { API_NAMES } from '../constants/App';
import { amplifyAPIBaseQuery } from '../utils/apiUtils';

export const s3Api = createApi({
  reducerPath: 's3Api',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: amplifyAPIBaseQuery({
    apiName: API_NAMES.PORTAL,
    baseUrl: '/s3',
  }),
  endpoints: (builder) => ({
    /**
     * Gets a list of asset file urls from S3
     *
     * @param bucket
     * @param clientId
     * @return {Promise}
     */
    getAssetFiles: builder.query<{ files: string[] }, { bucket: string; clientId: string }>({
      query: ({ bucket, clientId }) => ({
        url: `/assets/${bucket}/${clientId}`,
        method: 'get',
        init: {},
      }),
    }),

    /**
     * Gets a file url from S3
     *
     * @param bucket
     * @param path
     * @return {Promise}
     */
    getFileUrl: builder.query<string, { bucket: string; path: string; displayToastOnError?: boolean }>({
      query: ({ bucket, path, displayToastOnError = true }) => ({
        url: `/fileUrl/${bucket}?path=${path}`,
        method: 'get',
        displayToastOnError,
      }),
    }),

    /**
     * Gets a file from S3
     *
     * @param bucket
     * @param path
     * @return {Promise}
     */
    getFile: builder.query<any, { bucket: string; path: string }>({
      query: ({ bucket, path }) => ({
        url: `/file/${bucket}?path=${path}`,
        method: 'get',
      }),
    }),

    /**
     * Gets a file versions from S3
     *
     * @param bucket
     * @param prefix
     * @return {Promise}
     */
    getFileVersions: builder.query<any, { bucket: string; prefix: string }>({
      query: ({ bucket, prefix }) => ({
        url: `/file-versions/${bucket}?prefix=${prefix}`,
        method: 'get',
      }),
    }),

    /**
     * Uploads a file to S3
     *
     * @param bucket
     * @param path
     * @param file
     */
    uploadFile: builder.mutation<
      { fileUrl: string },
      { bucket: string; path: string; file: { name: string; content: any; contentType: string }; privateFile?: boolean }
    >({
      query: ({ bucket, path, file, privateFile }) => ({
        url: `/upload/${bucket}`,
        method: 'post',
        init: {
          body: {
            path,
            file,
            privateFile,
          },
        },
      }),
    }),

    /**
     * Copy a file from one bucket to another in S3
     *
     * @param sourceBucket
     * @param sourceFilePath
     * @param destinationBucket
     * @param destinationFilePath
     * @returns {Promise<void>}
     */
    copyFile: builder.mutation<
      void,
      { sourceBucket: string; sourceFilePath: string; destinationBucket: string; destinationFilePath: string }
    >({
      query: ({ sourceBucket, sourceFilePath, destinationBucket, destinationFilePath }) => ({
        url: `/copy`,
        method: 'post',
        init: {
          body: {
            sourceBucket,
            sourceFilePath,
            destinationBucket,
            destinationFilePath,
          },
        },
      }),
    }),

    /**
     * Deletes a given file from S3
     * @param bucket
     * @param path
     * @returns {Promise}
     */
    deleteFile: builder.mutation<any, { bucket: string; path: string }>({
      query: ({ bucket, path }) => ({
        url: `/file/${bucket}`,
        method: 'del',
        init: {
          body: {
            path,
          },
        },
      }),
    }),

    /**
     * Create a bucket in S3
     *
     * @param {string} bucket
     * @param { { name: string; text: string } } indexParams
     * @returns {Promise}
     */
    createBucket: builder.mutation<any, { bucket: string; indexParams: { name: string; text: string } }>({
      query: ({ bucket, indexParams }) => ({
        url: `/bucket`,
        method: 'post',
        init: {
          body: {
            bucket,
            indexParams,
          },
        },
      }),
    }),

    /**
     * Create a new vendor page for a given client and environment
     *
     * @param {string} environment
     * @param {string} clientId
     * @returns {Promise}
     */
    createVendorPage: builder.mutation<any, { environment: string; clientId: string }>({
      query: ({ environment, clientId }) => ({
        url: `/vendor-pages/${environment}/${clientId}`,
        method: 'post',
        init: {
          body: {},
        },
      }),
    }),
  }),
});

export const {
  useGetAssetFilesQuery,
  useGetFileUrlQuery,
  useGetFileQuery,
  useGetFileVersionsQuery,
  useUploadFileMutation,
  useCopyFileMutation,
  useDeleteFileMutation,
  useCreateBucketMutation,
  useCreateVendorPageMutation,
} = s3Api;
