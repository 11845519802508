import React from 'react';
import { useTranslation } from 'react-i18next';
import { I18nKeys } from '../constants/I18nKeys';
import { ClientDataType } from '../constants/ClientDataType';
import { PreviewDialog } from './PreviewDialog';
import { Dialogs } from '../constants/Dialogs';
import { usePricingRepo } from '../hooks/usePricingRepo';
import { useAppSelector } from '../hooks';
import { getClientUpdateBranch } from '../utils/pricingClientUpdateUtils';
import { PricingTab } from '../constants/Pricing';

export const PricingClientUpdatePreviewDialog: React.FC = () => {
  const { t } = useTranslation();

  const pricingTab = useAppSelector((state) => state.viewer.selectedPricingTabId);

  const { activeBranches } = usePricingRepo({
    useBranches: true,
  });
  const branch = activeBranches.find((metadata) => metadata.branchType === getClientUpdateBranch(pricingTab));

  return (
    <PreviewDialog
      dialogKey={Dialogs.PricingClientUpdatePreview}
      dialogTitle={t(
        pricingTab === PricingTab.Component
          ? I18nKeys.PricingComponentPreviewConfirmationTitle
          : I18nKeys.PricingBasePreviewConfirmationTitle,
      )}
      dialogMessage={t(I18nKeys.PricingBasePreviewConfirmationMessage)}
      dialogConfirmationButton={t(I18nKeys.PricingBasePreviewConfirmationButton)}
      branch={branch}
      structureBranch={getClientUpdateBranch(pricingTab)}
      dataType={ClientDataType.Supplier}
    />
  );
};
