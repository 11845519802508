import { Grid2 as Grid, Hidden } from '@mui/material';
import React from 'react';
import { SortableProperty } from '../types/SortableProperty';
import { SortProperty } from '../types/SortProperty';
import { TableSortMenu } from './TableSortMenu';

interface Props {
  filterRows?: JSX.Element;
  filterColumns?: JSX.Element;
  sortableProperties?: SortableProperty[];
  sortProperties?: SortProperty[];
  onSort(sortProperties: SortProperty[]): void;
}

export const TableControls: React.FC<Props> = ({
  filterRows,
  filterColumns,
  sortProperties = [],
  sortableProperties = [],
  onSort,
}: Props) => (
  <Grid
    style={{ backgroundColor: '#F5F5F5', placeContent: 'center', padding: '8px' }}
    container
    direction="row"
    alignItems="flex-start"
    justifyContent="space-between"
  >
    <Grid container size="grow" alignItems="center">
      {filterRows}
    </Grid>
    <Hidden smUp>
      <Grid>
        <TableSortMenu sortProperties={sortProperties} sortableProperties={sortableProperties} onSort={onSort} />
      </Grid>
    </Hidden>
    <Grid container alignItems="flex-end">
      {filterColumns}
    </Grid>
  </Grid>
);
