import { Permission } from '@idearoom/types';
import { SystemGroups } from '../constants/SystemGroups';
import { Group } from '../types/Group';
import { GroupMember } from '../types/GroupMember';
import { User } from '../types/User';
import { filterUserPermissions } from './permissionUtils';
import { GroupFilter } from '../types/CustomFilter';
import { TableFilterType } from '../constants/Viewer';

export const isIdeaRoomUserEmail = (email: string): boolean =>
  email.toLowerCase().endsWith('@idearoominc.com') || email.toLowerCase().endsWith('@idearoom.com');

export const isIdeaRoomUser = (user?: User | GroupMember): boolean => (user ? isIdeaRoomUserEmail(user.email) : false);

export const isIdeaRoomGroup = (groupId: string): boolean => groupId === SystemGroups.IdeaRoom;

export const isCurrentUserSuperUser = (user?: User): boolean =>
  (user !== undefined && user.permissions !== undefined && user.permissions.includes(Permission.SuperUser)) ||
  isIdeaRoomUser(user);

export const isCurrentUserAdmin = (user?: User): boolean =>
  (user !== undefined &&
    user.permissions !== undefined &&
    (user.permissions.includes(Permission.Admin) || user.permissions.includes(Permission.SuperUser))) ||
  isIdeaRoomUser(user);

export const isCurrentUserManager = (user?: User): boolean =>
  user !== undefined &&
  user.permissions !== undefined &&
  filterUserPermissions(user.permissions).includes(Permission.Manager);

export const isCurrentUserAdminOrManager = (user?: User): boolean =>
  isCurrentUserSuperUser(user) || isCurrentUserAdmin(user) || isCurrentUserManager(user);

export const isCurrentUserGroupAdmin = (user?: User, currentUserGroup?: Group, selectedGroup?: Group): boolean => {
  const isIdearoomGroup = selectedGroup !== undefined && isIdeaRoomGroup(selectedGroup.groupId);

  return (
    currentUserGroup !== undefined &&
    selectedGroup !== undefined &&
    ((currentUserGroup.groupId === selectedGroup.groupId && isCurrentUserAdmin(user)) ||
      (isIdeaRoomGroup(currentUserGroup.groupId) && !isIdearoomGroup))
  );
};

export const isCurrentUserGroupSuperUser = (user?: User, currentUserGroup?: Group, selectedGroup?: Group): boolean => {
  const isIdearoomGroup = selectedGroup !== undefined && isIdeaRoomGroup(selectedGroup.groupId);

  return (
    currentUserGroup !== undefined &&
    selectedGroup !== undefined &&
    ((currentUserGroup.groupId === selectedGroup.groupId && isCurrentUserSuperUser(user)) ||
      (isIdeaRoomGroup(currentUserGroup.groupId) && !isIdearoomGroup))
  );
};

/**
 * Gets rows to be displayed in the user table
 * Separates out rows to display which groups a user belongs to
 *
 * @param users list of users
 * @returns list of user and possibly their group rows
 */
export const getUserRows = (
  users: (GroupMember | (GroupMember & { groups: { id: string; name: string; permissions: Permission[] }[] }))[],
) =>
  users.flatMap((user) => [
    { ...user, path: [user.username] },
    ...('groups' in user
      ? user.groups.map(({ id: groupId, name: groupName, permissions }, i) => ({
          ...user,
          groupId,
          groupName,
          permissions,
          path: [user.username, `${i}`],
        }))
      : []),
  ]);

/**
 * Filters group members by the selected filters
 *
 * @param groupId the group being viewed
 * @param members all group members
 * @param filters all group filters
 * @param isIdeaRoomGroup whether the group is the IdeaRoom group
 * @returns filtered group members
 */
export const getFilteredMembers = (
  groupId: string | undefined,
  members: (GroupMember | (GroupMember & { groups: { id: string; name: string; permissions: Permission[] }[] }))[],
  filters: GroupFilter[],
) => {
  const groupFilter = filters.find((filter) => filter.groupId === groupId);

  let filteredMembers = members || [];
  if (groupFilter) {
    const userFilters = groupFilter.tableFilters.filter(
      (tableFilter) => tableFilter.tableFilterType === TableFilterType.User,
    );

    // Filter by custom filters if applied
    if (userFilters && userFilters.length > 0) {
      userFilters[0].filters.forEach((userFilter) => {
        if (!userFilter.selectedFilterValues.some((selectedValue) => selectedValue.key === 'all')) {
          switch (userFilter.filterType) {
            case 'Dealer':
              filteredMembers = filteredMembers.filter((member) =>
                userFilter.selectedFilterValues.some(
                  (selectedValue) =>
                    (member.dealers.length === 0 && selectedValue.key === 'Not Assigned') ||
                    member.dealers.includes(selectedValue.key),
                ),
              );
              break;

            case 'Permission':
              filteredMembers = filteredMembers.filter((member) =>
                userFilter.selectedFilterValues.some((selectedValue) =>
                  [
                    ...(member.permissions || []),
                    ...('groups' in member ? member.groups.flatMap(({ permissions: p = [] }) => p) : []),
                  ]?.some((permission) => permission === selectedValue.key),
                ),
              );
              break;

            default:
              break;
          }
        }
      });
    }
  }

  return filteredMembers;
};
