import { useEffect } from 'react';
import { IntegrationStatus, SystemNotificationKey } from '@idearoom/types/lib/types/src';
import { notificationApi, useGetPaymentSignupNotificationQuery } from '../services/notificationApi';
import { AppState } from '../types/AppState';
import { useAppDispatch, useAppSelector } from '../hooks';
import { unknownGroup } from '../constants/Group';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';

export const usePaymentSignupNotification = () => {
  const dispatch = useAppDispatch();
  const paymentIntegrationStatus = useAppSelector((state) => state?.viewer?.paymentIntegrationStatus);
  const { group: { groupId } = unknownGroup } = useAppSelector((state: AppState) => state?.currentUser);
  const selectedClientId = useAppSelector((state: AppState) => state?.viewer?.selectedClientId);
  const { currentData: notifications } = useGetPaymentSignupNotificationQuery(
    { groupId },
    {
      refetchOnFocus: false,
      refetchOnReconnect: false,
      skip: !groupId || paymentIntegrationStatus?.status !== IntegrationStatus.Connected || !fetch,
    },
  );

  useEffect(() => {
    if (notifications && notifications.length > 0 && selectedClientId) {
      dispatch(openDialog({ dialog: Dialogs.AdyenPaymentsNotification }));
      dispatch(
        notificationApi.endpoints.addNotificationEvent.initiate({
          clientId: selectedClientId,
          groupId,
          notificationId: SystemNotificationKey.PaymentSignup,
        }),
      );
    }
  }, [notifications, dispatch, groupId, selectedClientId]);
};
