import { AnyAction, Reducer } from 'redux';
import { action } from 'typesafe-actions';
import { NotificationDialogState } from '../types/NotificationDialogState';

// Action types

export enum NotificationDialogActionTypes {
  CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION',
  OPEN_NOTIFICATION = 'OPEN_NOTIFICATION',
}

// Reducer

export const INITIAL_NOTIFICATION_STATE: NotificationDialogState = {
  notificationTitle: undefined,
  notificationContent: undefined,
};

export const notificationDialogReducer: Reducer<NotificationDialogState> = (
  state = INITIAL_NOTIFICATION_STATE,
  notificationAction,
) => {
  switch (notificationAction.type) {
    case NotificationDialogActionTypes.CLOSE_NOTIFICATION: {
      return { notificationTitle: undefined, notificationContent: undefined };
    }

    case NotificationDialogActionTypes.OPEN_NOTIFICATION: {
      const {
        payload: { notificationTitle, notificationContent },
      } = notificationAction;

      return {
        ...state,
        notificationTitle,
        notificationContent,
      };
    }

    default: {
      return state;
    }
  }
};

// Action creators

export const openNotificationDialog = (notificationTitle: string, notificationContent: string): AnyAction =>
  action(NotificationDialogActionTypes.OPEN_NOTIFICATION, { notificationTitle, notificationContent });

export const closeNotificationDialog = (): AnyAction => action(NotificationDialogActionTypes.CLOSE_NOTIFICATION);
